export type BrandIconType = {
  imagePath: string;
  clickImagePath: string;
  brandCode: string;
  brandName: string;
  firstChar: string;
};

export const brandIconOptions: BrandIconType[] = [
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon001.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon001-2.png`),
    brandCode: "PA",
    brandName: "페이퍼먼츠",
    firstChar: "페",
  },
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon002.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon002.png`),
    brandCode: "AT",
    brandName: "아뗄릭",
    firstChar: "아",
  },
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon003.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon003-2.png`),
    brandCode: "VI",
    brandName: "비비에모",
    firstChar: "비",
  },
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon004.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon004-2.png`),
    brandCode: "ME",
    brandName: "메이첼",
    firstChar: "메",
  },
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon0010.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon0010-02.png`),
    brandCode: "SP",
    brandName: "페이퍼슈즈",
    firstChar: "슈",
  },
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon005.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon005-2.png`),
    brandCode: "CS",
    brandName: "RTV라라페",
    firstChar: "라",
  },
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon006.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon006-2.png`),
    brandCode: "MA",
    brandName: "맨즈건",
    firstChar: "맨",
  },
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon0011.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon0011.png`),
    brandCode: "LC",
    brandName: "캣츠드림(LC)",
    firstChar: "캣",
  },
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon0011.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon0011.png`),
    brandCode: "CA",
    brandName: "캣츠드림(CA)",
    firstChar: "캣",
  },
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon009.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon009-2.png`),
    brandCode: "CH",
    brandName: "시크힐즈",
    firstChar: "시",
  },
  {
    imagePath: require(`../../../assets/image/brand-logo/p_icon0013.png`),
    clickImagePath: require(`../../../assets/image/brand-logo/p_icon0013.png`),
    brandCode: "KI",
    brandName: "비첼라",
    firstChar: "비",
  },
];

export const brandOptions = brandIconOptions.map((v) => ({ label: v.brandName, value: v.brandCode }));
export const brandCodeToBrandNameMap = brandIconOptions.reduce((acc: { [key: string]: string }, cur: BrandIconType) => {
  acc[cur.brandCode] = cur.brandName;
  return acc;
}, {});
