import { customAxios } from "../../config/axios-config";

import {
  getProductRequestDto,
  getProductResponseDto,
  getProductListRequestDto,
  createProductDto,
  updateProductDto,
  ProductInRackItemDto,
  moveRackToRackDto,
  newProductRequestDto,
  newProductResponseDto,
  createPreProductRequestDto,
  deletePreProductDto,
  getPreProductDto,
  updateImageRequestDto,
  translateDto,
  updateIsInUseDto,
  getBulkEstimateExcelFileDto,
  getProductStatusDto,
  createBarCodeDto,
} from "./types";

export const productApi = {
  getProductExcelData: async (params: newProductRequestDto): Promise<newProductResponseDto> => {
    return await customAxios.get(`/products/`, { params }).then((res) => res.data);
  },

  getProduct: async (data: getProductRequestDto): Promise<getProductResponseDto> => {
    return await customAxios.get(`/products/${data.BarCode}/`).then((res) => res.data);
  }, //새로운 api

  getNewProductList: async (params?: newProductRequestDto): Promise<newProductResponseDto> => {
    return await customAxios.get(`/products/`, { params }).then((res) => {
      const newResult = res.data.results.map((item: any, i: number) => {
        const { PreProductID, ...rest } = item; // PreProductID와 나머지 필드 분리
        const { ImageLink, ...rest2 } = PreProductID; // PreProductID 필드 분리
        return { ...rest, ...rest2, MainImageLink: ImageLink }; // 병합
      });
      return { ...res.data, results: newResult };
    });
  },
  getPreProductList: async (params: newProductRequestDto): Promise<any> => {
    const { sortby, ...rest } = params;
    return await customAxios.get(`/pre_product/`, { params: { ...rest } }).then((res) => res.data);
  },
  getPreProductById: async (params: { id: string }): Promise<any> => {
    const { id } = params;
    return await customAxios.get(`/pre_product/${id}/`).then((res) => res.data);
  },
  getProductList: async (params?: getProductListRequestDto): Promise<getProductResponseDto[]> => {
    return await customAxios.get(`/products/`, { params }).then((res) => res.data);
  },

  createProduct: async (data: createProductDto): Promise<createProductDto> => {
    return await customAxios.post(`/products/`, data).then(() => data);
  },

  createAllProduct: async (data: createProductDto[]): Promise<createProductDto[]> => {
    return await customAxios.post(`/products/`, data).then(() => data);
  },

  updateProduct: async (data: updateProductDto): Promise<any> => {
    const { BarCode, ...body } = { ...data };

    return await customAxios.patch(`/products/${BarCode}/`, body).then(() => data);
  },

  udpateProductIntoRack: async (body: ProductInRackItemDto): Promise<ProductInRackItemDto> => {
    return await customAxios.post(`/inventories/move/`, body).then(() => body);
  },

  moveRackToRack: async (body: moveRackToRackDto): Promise<moveRackToRackDto> => {
    return await customAxios.post(`/inventories/move_rr/`, body).then(() => body);
  },

  updateProductBulk: async (body: updateProductDto[]) => {
    return await customAxios.post("/products/bulk_update/", body).then((res) => res.data);
  },
  deleteProduct: async (barcode: string) => {
    return await customAxios.delete(`/products/${barcode}/`).then(() => barcode);
  },
  createPreProduct: async (data: createPreProductRequestDto) => {
    return await customAxios.post(`/pre_product/getOneBound/`, data).then((res) => res.data);
  },
  getCoupangCategory: async () => {
    return await customAxios.get(`/get_category_coupang/`).then((res) => res.data);
  },
  deletePreProduct: async (patmas: deletePreProductDto) => {
    const { id } = patmas;
    return await customAxios.delete(`/pre_product/${id}/`).then((res) => res.data);
  },
  updatePreProduct: async (data: getPreProductDto) => {
    const { Id, products, ImageLink, ImageLink_sebu, IsInUse, ...body } = data;
    return await customAxios.patch(`/pre_product/${Id}/`, body).then((res) => res.data);
  },

  updateImage: async (data: updateImageRequestDto) => {
    const formData = new FormData();
    const nofile = new File([""], "None", { type: "none" });
    data.image.forEach((file: File | string) => {
      if (typeof file === "string") {
        formData.append("image", nofile); /// 빈값을 삽입할 때
      } else {
        formData.append("image", file); /// 파일을 삽입할 때
      }
    });
    formData.append("object_type", data.object_type); // type
    formData.append("ids", JSON.stringify(data.ids)); // ['바코드','바코드','바코드']
    return await customAxios
      .post(`/manage_image/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  },

  translateZh: async (data: translateDto) => {
    return await customAxios.post(`/translate/`, data).then((res) => res.data);
  },
  updateIsInUse: async (body: updateIsInUseDto) => {
    return await customAxios.post(`/pre_product/setIsInUse/`, body).then((res) => res.data);
  },

  //대량견적 템플릿 추출하기
  getBulkEstimateExcelFile: async (body: getBulkEstimateExcelFileDto) => {
    return await customAxios
      .post(`/make_excel_file/`, body, {
        responseType: "blob", // 여기서 blob으로 지정
      })
      .then((res) => res.data);
  },

  getProductStatus: async (body: getProductStatusDto): Promise<any> => {
    return await customAxios.post(`/products/update_coupang_status/`, body).then((res) => res.data);
  },

  createProductAtDome88: async (body: { preproduct_id: number }): Promise<any> => {
    return await customAxios.post(`/pre_product/setD8/`, body).then((res) => res.data);
  },
  createBarCode: async (data: createBarCodeDto): Promise<any> => {
    const body = { ...data, IsUse: 1 };
    return await customAxios.post(`/products/`, body).then((res) => res.data);
  },
  
};

