import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import RowStack from "../../../../shared/ui/RowStack";
import noImage from "../../../../assets/image/no_image.png";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import { getProductResponseDto } from "../../../../service/product/types";
import { createLabel } from "../../../../shared/lib/createLabel";
interface Props {
  selected: number[];
  products: getProductResponseDto[];
}

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_URL;

export const CreateLebelModal: React.FC<Props> = ({ selected, products }) => {
  const [open, setOpen] = useState(false);
  const [careLabelRepeatList, setCareLabelRepeatList] = useState<number[]>([]);
  const [barCodeLabelRepeatList, setBarCodeLabelRepeatList] = useState<number[]>([]);

  const handleChangeOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setBarCodeLabelRepeatList(selected.map(() => 1));
    setCareLabelRepeatList(selected.map(() => 0));
  }, [selected]);

  const selectedProducts: getProductResponseDto[] = useMemo(() => {
    return products.filter((product, i) => selected.includes(i)) || [];
  }, [selected, products]);

  const handleChangeCareLabelCnt = (e: any, i: number) => {
    const value = e.target.value;
    const newCareLabelCnt = [...careLabelRepeatList];
    newCareLabelCnt[i] = Number(value);
    setCareLabelRepeatList(newCareLabelCnt);
  };

  const handleChangeBarcodeLabelCnt = (e: any, i: number) => {
    const value = e.target.value;
    const newBarcodeLabelCnt = [...barCodeLabelRepeatList];
    newBarcodeLabelCnt[i] = Number(value);
    setBarCodeLabelRepeatList(newBarcodeLabelCnt);
  };

  const handleCreateLabel = () => {
    createLabel({
      products: selectedProducts,
      barCodeLabelRepeatList,
      careLabelRepeatList,
    });
  };

  return (
    <>
      <MenuItem onClick={handleChangeOpen}>
        <ListItemIcon>
          <PrintIcon />
        </ListItemIcon>
        라벨출력
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleChangeOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "1100px",
              maxWidth: "1100px",
              // minWidth: "800px",
              // height: "620px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <RowStack>
            <Typography>라벨 출력</Typography>
            <IconButton onClick={handleChangeOpen}>
              <CloseIcon />
            </IconButton>
          </RowStack>
        </DialogTitle>
        <DialogContent>
          {selectedProducts.length !== 0 && (
            <TableContainer sx={{ height: 400, boxSizing: "border-box", border: "0.5px solid #DFDFDF" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align={"left"} style={{ minWidth: 100 }}>
                      이미지
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 100, maxWidth: 200 }}>
                      상품명
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 60 }}>
                      바코드
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 90 }}>
                      바코드라벨 수량
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 90 }}>
                      케어라벨 수량
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProducts.map((item, i) => {
                    // if (true) {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        <TableCell align={"left"}>
                          <img
                            alt="상품 이미지"
                            src={item?.ImageLink ? `${IMAGE_BASE_URL}/${item.ImageLink[0]}` : noImage}
                            width="100"
                            height="100"
                          />
                        </TableCell>
                        <TableCell align={"center"} width="250">{`${item.ProductName_ko} (${item.Color}, ${item.Size})`}</TableCell>
                        <TableCell align={"center"}>{item.BarCode}</TableCell>
                        <TableCell align={"center"}>
                          <TextField
                            value={barCodeLabelRepeatList[i]}
                            onChange={(e) => handleChangeBarcodeLabelCnt(e, i)}
                            type="number"
                            sx={{ width: "80px" }}
                          />
                        </TableCell>
                        <TableCell align={"center"}>
                          <TextField
                            value={careLabelRepeatList[i]}
                            onChange={(e) => handleChangeCareLabelCnt(e, i)}
                            type="number"
                            sx={{ width: "80px" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleCreateLabel} variant="contained" color="primary">
            출력
          </Button>
          <Button onClick={handleChangeOpen} variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
