import { Stack, TextField, Typography } from "@mui/material";
import LinkSubTitle from "../LinkSubTitle";
import { getPreProductDto, updateImageRequestDto } from "../../../../service/product/types";
import { useBrandCodeStore } from "../../../../store/brandStore";
import { useEffect, useState } from "react";
import ImageUpload from "./ImageUpload";
import { useUpdateImage } from "../../../../service/product/productHooks";
import { customToast } from "../../../../shared/lib/customToast";
import RowStack from "../../../../shared/ui/RowStack";
import LinkCard from "../LinkCard";
interface ProductInfoFormProps {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
}

const url = process.env.REACT_APP_API_URL;

const ProductInfoForm: React.FC<ProductInfoFormProps> = ({ data, onChange }) => {
  const { BrandImage, BrandName, setBrandCode } = useBrandCodeStore((v) => v);
  const [preProductImage, setPreProductImage] = useState<string[]>([]);
  const { mutateAsync: updateImage } = useUpdateImage();

  const handleChangeImage = async (maxImages: number, acceptedFiles: File[]) => {
    const newImags = [...preProductImage, ...acceptedFiles].slice(-maxImages);
    const dto: updateImageRequestDto = { object_type: "pre_product", ids: [data.Id.toString()], image: newImags };
    try {
      await updateImage(dto);
      setPreProductImage(newImags.map((image) => (typeof image === "string" ? image : URL.createObjectURL(image)))); // 이미지로 전부 변환해서 반환
    } catch (e) {
      customToast("error", "이미지 업로드에 실패했습니다.");
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    onChange((prev: getPreProductDto) => ({ ...prev, [name]: value }));
  };

  const handleRemoveImage = () => {
    const newImags = [...preProductImage].slice(0, -1);
    try {
      const dto: updateImageRequestDto = { object_type: "pre_product", ids: [data.Id.toString()], image: newImags };
      updateImage(dto);
      setPreProductImage((prevImages) => prevImages.slice(0, -1));
    } catch {
      customToast("error", "이미지 삭제에 실패했습니다.");
    }
  };

  useEffect(() => {
    setBrandCode(data.Brand);
  }, []);

  useEffect(() => {
    if (data.ImageLink) {
      setPreProductImage(data.ImageLink.map((image) => `${url}/media/${image}`));
    }
  }, [data]);

  return (
    <>
      <LinkCard>
        <RowStack>
          <Stack flex={2} alignItems={"center"} justifyContent={"center"} alignContent={"center"}>
            <ImageUpload width={"400px"} height={"400px"} images={preProductImage} onChange={handleChangeImage} onRemoveImage={handleRemoveImage} />
          </Stack>
          <Stack flex={2} pl={3} gap={5}>
            <Stack flex={1} gap={2} sx={{ width: "420px" }}>
              <LinkSubTitle title="브랜드" />
              <Stack flexDirection={"row"} justifyContent={"start"} alignItems={"center"} gap={2} sx={{ height: "60px" }}>
                <img width={50} height={50} src={BrandImage} alt="브랜드 이미지" />
                <Typography>{BrandName}</Typography>
              </Stack>
            </Stack>
            <Stack flexDirection={"row"} flex={1}>
              <Stack flex={1} gap={2}>
                <LinkSubTitle title="상품 번호" />
                <Stack flexDirection={"row"}>
                  <Typography mr={1} color={"#787878"}>
                    NO.
                  </Typography>
                  <Typography>{data.ProductCode}</Typography>
                </Stack>
              </Stack>
              <Stack flex={1} gap={2}>
                {data.SupplierCompany === "vvic" && (
                  <>
                    <LinkSubTitle title="스타일 번호" />
                    <TextField
                      variant="outlined"
                      size="small"
                      sx={{ width: "120px" }}
                      value={data.StyleNumber}
                      name="StyleNumber"
                      onChange={handleChange}
                      error={!data.StyleNumber}
                    />
                  </>
                )}
              </Stack>
              {data.SupplierCompany === "vvic" && (
                <Stack flex={1} gap={2}>
                  <LinkSubTitle title="구매 주소" />

                  <TextField
                    variant="outlined"
                    size="small"
                    sx={{ width: "150px" }}
                    value={data.purchaseadress}
                    name="purchaseadress"
                    onChange={handleChange}
                    error={!data.purchaseadress}
                    helperText={
                      <Typography variant="caption" color="red">
                        {/[가-힣]/.test(data.purchaseadress) ? "구매주소를 중국어로 작성해주세요." : ""}
                      </Typography>
                    }
                  />
                </Stack>
              )}
            </Stack>
            <Stack gap={2} flex={1}>
              <LinkSubTitle title="상품명" />
              <Stack flexDirection={"row"}>
                <Typography mr={1} color={"#787878"} fontSize={12}>
                  중국어 상품명:
                </Typography>
                <Typography fontSize={12} color={"#5b5b5b"}>
                  {data.ProductName_zh}
                </Typography>
              </Stack>
              <TextField
                variant="outlined"
                value={data.ProductName_ko}
                name="ProductName_ko"
                onChange={handleChange}
                error={!data.ProductName_ko}
                helperText={
                  <Typography variant="caption" color="red">
                    {data.ProductName_ko.includes(data.StyleNumber) ? "상품번호가 아닌 스타일번호가 입력된것 습니다 !" : ""}
                  </Typography>
                }
              />
            </Stack>
            <RowStack gap={3}>
              <AccessTimeIcon />
              <Typography variant="subtitle1" width={"100%"} color="#666">
                생성시간 : {data.CreatedAt.split("T").join(" ")}
              </Typography>
            </RowStack>
          </Stack>
        </RowStack>
      </LinkCard>
    </>
  );
};

export default ProductInfoForm;
import AccessTimeIcon from "@mui/icons-material/AccessTime";
