import { Typography, OutlinedInput, Grid, Button } from "@mui/material";
import LinkCard from "../LinkCard";
import LinkSubTitle from "../LinkSubTitle";
import RowStack from "../../../../shared/ui/RowStack";
import { getPreProductDto } from "../../../../service/product/types";
import SelectForm from "../../../../widget/form/ui/SelectForm";
import { brandOptions } from "../../../side-navigation/const/brandIconsOption";
import { useMemo } from "react";

interface Props {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
}

const ProductAdditionalInfoForm: React.FC<Props> = ({ data, onChange }) => {
  const handleChangeData = (e: any) => {
    const { name, value } = e.target;
    onChange((prev: getPreProductDto) => ({ ...prev, [name]: value }));
  };

  const SeasonOptions = [
    {
      value: "1",
      label: "SPRING",
    },
    {
      value: "2",
      label: "SUMMER",
    },
    {
      value: "3",
      label: "FALL",
    },
    {
      value: "4",
      label: "WINTER",
    },
    {
      value: "5",
      label: "YEAR_ROUND",
    },
  ];

  const brandName = useMemo(() => {
    return brandOptions.find((brand) => brand.value === data.Brand)?.label;
  }, []);

  const handleCreatePreProductInfo = () => {
    const today = new Date();
    // 1달 뒤로 설정
    today.setMonth(today.getMonth() + 1);

    // 연도와 월(01~12 형태 유지 위해 padStart 사용)
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");

    onChange((prev) => ({
      ...prev,
      Season: "1",
      MadeCountry: "중국",
      FirstSaleSeason: `${year}년 ${month}월`,
      Laundry: prev.Brand === "SP" ? "오염된 부분을 전용 세제로 세척해주세요." : "단독세탁 혹은 드라이클리닝",
      BuyerName: `${brandName} 협력사`,
      DisplayAdress: "경기도 부천 조마루로 427번길 106 4층 (032-710-0992)",
    }));
  };

  return (
    <LinkCard>
      <RowStack gap={2} sx={{ mb: 2 }}>
        <LinkSubTitle title="상품상세 정보" required />
        <Button variant="outlined" size="large" onClick={handleCreatePreProductInfo}>
          상품정보 자동생성
        </Button>
      </RowStack>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <RowStack gap={2}>
            <Typography width={200} variant="subtitle2">
              계절
            </Typography>
            <SelectForm options={SeasonOptions} size="small" value={data.Season} id="Season" handleChange={handleChangeData} />
          </RowStack>
        </Grid>
        <Grid item xs={6}>
          <RowStack gap={2}>
            <Typography width={200} variant="subtitle2">
              사용 연령
            </Typography>
            <OutlinedInput
              name="UseAge"
              size="small"
              fullWidth
              value={data.UseAge}
              onChange={handleChangeData}
              // error={!data.UseAge}
            />
          </RowStack>
        </Grid>
        <Grid item xs={6}>
          <RowStack gap={2}>
            <Typography width={200} variant="subtitle2">
              제조 국명
            </Typography>
            <OutlinedInput name="MadeCountry" size="small" fullWidth value={data.MadeCountry} onChange={handleChangeData} error={!data.MadeCountry} />
          </RowStack>
        </Grid>
        <Grid item xs={6}>
          <RowStack gap={2}>
            <Typography width={200} variant="subtitle2">
              최초 판매 시즌
            </Typography>
            <OutlinedInput
              name="FirstSaleSeason"
              size="small"
              fullWidth
              value={data.FirstSaleSeason}
              onChange={handleChangeData}
              error={!data.FirstSaleSeason}
            />
          </RowStack>
        </Grid>
        <Grid item xs={6}>
          <RowStack gap={2}>
            <Typography width={200} variant="subtitle2">
              수입자 명
            </Typography>
            <OutlinedInput name="BuyerName" size="small" fullWidth value={data.BuyerName} onChange={handleChangeData} error={!data.BuyerName} />
          </RowStack>
        </Grid>
        <Grid item xs={6}>
          <RowStack gap={2}>
            <Typography width={200} variant="subtitle2">
              세탁 방법
            </Typography>
            <OutlinedInput name="Laundry" size="small" fullWidth value={data.Laundry} onChange={handleChangeData} error={!data.Laundry} />
          </RowStack>
        </Grid>
        <Grid item xs={12}>
          <RowStack gap={2}>
            <Typography width={160} variant="subtitle2">
              표시자 주소(연락처)
            </Typography>
            <OutlinedInput
              name="DisplayAdress"
              size="small"
              fullWidth
              value={data.DisplayAdress}
              onChange={handleChangeData}
              error={!data.DisplayAdress}
            />
          </RowStack>
        </Grid>
      </Grid>
    </LinkCard>
  );
};

export default ProductAdditionalInfoForm;
