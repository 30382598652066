import { Button, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import LinkCard from "./LinkCard";
import CategoryForm from "./form/CategoryForm";
import ProductAdditionalInfoForm from "./form/ProductAdditionalInfoForm";
import ProductInfoForm from "./form/ProductInfoForm";
import ProductOptionsForm from "./form/ProductOptionsForm";
import { getOriginalProductResponseDto, getPreProductDto } from "../../../service/product/types";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  useCreateProductAtDome88,
  useDeletePreProduct,
  useGetPreProductById,
  useUpdateIsInUse,
  useUpdatePreProduct,
  useUpdateProductBulk,
} from "../../../service/product/productHooks";
import { customToast } from "../../../shared/lib/customToast";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import ProductDetailImage from "./form/ProductDetailImage";
import { preproduct_initial_value } from "../const/ProductsInitialValue";
import { product_initial_value } from "../const/preProductInitialValue";
import RowStack from "../../../shared/ui/RowStack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RefreshIconButton from "../../../shared/ui/buttom/RefreshIconButton";
import SaveIcon from "@mui/icons-material/Save";
import ProductMemoForm from "./form/ProductMemoForm";
import ProductProcessStep from "./form/ProductProcessStep";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ProductSearchTag from "./form/ProductSearchTag";
import { getSearchTagDto } from "../../../service/search-tag/types";
import { StatusBadge } from "../../product/style/StatusBadge";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { FORM_LIST, checkForm } from "../lib/checkForm";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import { STEP_LIST } from "../const/stepList";
import NewFiberCompositionForm from "./form/NewFiberCompositionForm";

const ABLE_BRAND_LIST = ["PA", "AT", "VI", "ME", "SP"];

const UpdateSingleProduct = () => {
  const location = useLocation();

  /***
   * 이미지를 담는게 4개 있어야함
   * 1. 제품이미지
   * 2. 제품 상세이미지
   * 3. 바코드 대표이미지
   * 4. 바코드 추가이미지
   *
   * 1,2 한묶음
   * 3,4 리스트로 한묶음
   */
  const [preProduct, setPreProduct] = useState<getPreProductDto>(preproduct_initial_value);
  const [products, setProducts] = useState<getOriginalProductResponseDto[]>([product_initial_value]);
  const { mutateAsync: deletePreProduct } = useDeletePreProduct();
  const id = location.pathname.split("/")[3];
  const { data, refetch, isLoading, isFetching } = useGetPreProductById({ id });
  const navigate = useNavigate();
  const { mutateAsync: updatePreProduct, isPending: p1 } = useUpdatePreProduct();
  const { mutateAsync: updateProductBulk, isPending: p2 } = useUpdateProductBulk();
  const { mutateAsync: updateIsInUse, isPending: p3 } = useUpdateIsInUse();
  const { mutateAsync: createDome88Product } = useCreateProductAtDome88();
  const refs = useRef<{ [key: string]: any }>({});

  const scrollToForm = (formName: string) => {
    if (refs.current[formName]) {
      refs.current[formName].scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (data) {
      setPreProduct(data);
      setProducts(data.products);
    }
  }, [data]);

  useEffect(() => {
    updateIsInUse({ Id: id, IsInUse: true });
    return () => {
      updateIsInUse({ Id: id, IsInUse: false });
    };
  }, []);

  const handleUpdateSubmit = async () => {
    let doNotSave = false;

    if (preProduct.PreProductStatus === 6) {
      for (let i = 0; i < FORM_LIST.length; i++) {
        const title = FORM_LIST[i];
        const isFormComplete = checkForm(preProduct, products, title);
        if (!isFormComplete) {
          scrollToForm(title);
          customToast("error", `${title} 정보를 입력해주세요.`);
          doNotSave = true;
          break;
        }
      }
    }

    if (doNotSave) return;

    try {
      await updateProductBulk(
        products.map((product) => _.omit(product, ["PreProductID", "ImageLink", "ImageLink_add", "ProductStatus", "QuoteDate"]))
      );
      await updatePreProduct({ ...preProduct });
      customToast("success", "상품이 업데이트 되었습니다.");
      refetch();
      // window.history.back();
    } catch (e) {
      customToast("error", "상품 업데이트에 실패했습니다.");
    }
  };

  const handleDeletePreProduct = async () => {
    if (confirm("정말 삭제하시겠습니까?")) {
      await deletePreProduct({ id });
      window.history.back();
    }
  };

  const handleCreateDome88Product = async () => {
    const dto = { preproduct_id: preProduct.Id };
    await createDome88Product(dto);
  };

  const tagInfo: getSearchTagDto = useMemo(() => {
    const productName = preProduct.ProductName_ko;
    const options = products.map((v) => v.Color).join(", ");
    const sizes = products.map((v) => v.Size).join(", ");

    return { productName, options, sizes };
  }, [preProduct, products]);

  return (
    <>
      <BackDropLoading open={isLoading || isFetching || p1 || p2} />
      <Stack gap={2} mb={5}>
        {preProduct.Id !== 0 && (
          <>
            <LinkCard>
              <RowStack>
                <Typography>
                  상품링크 :{" "}
                  <a href={preProduct.Productlink} target="_blank">
                    {preProduct.Productlink}
                  </a>
                </Typography>
                <RowStack gap={2}>
                  {ABLE_BRAND_LIST.includes(preProduct.Brand) && (
                    <Button onClick={handleCreateDome88Product} variant="contained">
                      도팔에 상품등록
                    </Button>
                  )}
                  <Tooltip title={<p>상품 삭제</p>} arrow placement="top">
                    <IconButton color="error">
                      <DeleteOutlineIcon onClick={handleDeletePreProduct} fontSize="large" />
                    </IconButton>
                  </Tooltip>

                  <RefreshIconButton onClick={() => refetch()} />
                </RowStack>
              </RowStack>
            </LinkCard>
            <ProductProcessStep data={preProduct} onChange={setPreProduct} />
            <div ref={(el) => (refs.current["상품정보"] = el)}>
              <ProductInfoForm data={preProduct} onChange={setPreProduct} />
            </div>
            <ProductMemoForm data={preProduct} onChange={setPreProduct} />
            <div ref={(el) => (refs.current["카테고리"] = el)}></div>
            <CategoryForm data={preProduct} onChange={setPreProduct} />
            <div ref={(el) => (refs.current["검색태그"] = el)}>
              <ProductSearchTag data={preProduct} onChange={setPreProduct} tagInfo={tagInfo} />
            </div>
            <div ref={(el) => (refs.current["옵션정보"] = el)}>
              <ProductOptionsForm data={products} onChange={setProducts} Brand={preProduct.Brand} preProductID={preProduct.Id} />
            </div>
            <div ref={(el) => (refs.current["상세페이지"] = el)}>
              <ProductDetailImage data={preProduct} onChange={setPreProduct} />
            </div>
            <div ref={(el) => (refs.current["섬유의조성"] = el)}>
              <NewFiberCompositionForm data={preProduct} onChange={setPreProduct} />
            </div>
            <div ref={(el) => (refs.current["상품세부정보"] = el)}>
              <ProductAdditionalInfoForm data={preProduct} onChange={setPreProduct} />
            </div>
          </>
        )}
        <Stack
          gap={3}
          sx={{ position: "fixed", bottom: "10%", right: "calc(50% - 845px)", alignItems: "center", transform: "translateY(-10%)", zIndex: 1000 }}
        >
          <LinkCard sx={{ width: "200px" }}>
            <Typography color="#333" sx={{ mb: 2 }}>
              상품 등록 진행도
            </Typography>
            <List>
              <ListItem>
                <LabelOutlinedIcon sx={{ mr: 4, color: "#666" }} />
                {preProduct.PreProductStatus !== null && preProduct.PreProductStatus !== undefined && (
                  <StatusBadge
                    color={STEP_LIST[preProduct.PreProductStatus].color}
                    backgroundColor={STEP_LIST[preProduct.PreProductStatus].backgroundColor}
                  >
                    {STEP_LIST[preProduct.PreProductStatus].title}{" "}
                  </StatusBadge>
                )}
              </ListItem>

              <Divider sx={{ my: 2 }} />
              {FORM_LIST.map((title, i) => {
                const isFormComplete = checkForm(preProduct, products, title);
                return (
                  <ListItemButton key={i} onClick={() => scrollToForm(title)} sx={{ opacity: isFormComplete ? 1 : 0.5 }}>
                    {isFormComplete ? <TaskAltIcon color={"primary"} sx={{ mr: 4 }} /> : <HighlightOffIcon color="error" sx={{ mr: 4 }} />}
                    <ListItemText>
                      <Typography sx={{ fontSize: "14px", color: "#333" }}>{title}</Typography>
                    </ListItemText>
                  </ListItemButton>
                );
              })}

              <Divider sx={{ my: 2 }} />
              <ListItem>
                <Button startIcon={<SaveIcon />} variant="contained" fullWidth onClick={handleUpdateSubmit} sx={{ height: "60px" }}>
                  저장하기
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  startIcon={<ExitToAppIcon />}
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    navigate("/products");
                  }}
                  sx={{ height: "60px" }}
                >
                  나가기
                </Button>
              </ListItem>
            </List>
          </LinkCard>
        </Stack>
      </Stack>
    </>
  );
};

export default UpdateSingleProduct;
