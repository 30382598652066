import { Button, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useModals } from "../../../service/modal/useModals";
import { PrintBarCodeLabelModal } from "./PrintBarCodeLabelModal";

import PrintIcon from "@mui/icons-material/Print";
const PrintBarCodeLabelButton = () => {
  const { openModal, closeModal } = useModals();

  const handleClose = () => {
    closeModal(PrintBarCodeLabelModal);
  };

  const handleClickOpen = () => {
    openModal(PrintBarCodeLabelModal, { handleClose });
  };

  return (
    <React.Fragment>
      <ListItemButton onClick={handleClickOpen}>
        <ListItemText
          primary="라벨 출력"
          sx={{
            "& .MuiListItemText-primary": {
              fontSize: "0.8rem", // primary 텍스트의 폰트 크기 설정
            },
          }}
        />
        <PrintIcon />
      </ListItemButton>
      {/* <Button startIcon={<PrintIcon/>} onClick={handleClickOpen} color="primary">
        바코드라벨 출력
      </Button> */}
    </React.Fragment>
  );
};

export default PrintBarCodeLabelButton;
