import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { brandIconOptions, BrandIconType } from "../../../side-navigation/const/brandIconsOption";
import { useBrandCodeStore } from "../../../../store/brandStore";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { useGetBulkEstimateExcelFile } from "../../../../service/product/productHooks";
import { getBulkEstimateExcelFileDto } from "../../../../service/product/types";
import { STEP_LIST } from "../../../link/const/stepList";
const DISABED_BRANDS = ["LC", "KI"];

const BulkEstimateTemplateBuilder = () => {
  const { BrandCode, setBrandCode } = useBrandCodeStore((state) => state);
  const [isCheck, setIsCheck] = useState(true);
  const [open, setOpen] = useState(false);
  const { mutateAsync: getExcelFile, isPending } = useGetBulkEstimateExcelFile();

  const handleGetExcelFile = async () => {
    const dto: getBulkEstimateExcelFileDto = {
      Brand: BrandCode,
      ischnagePreProductStatus: isCheck ? 1 : 0,
      targetPreProductStatus: STEP_LIST.findIndex((item) => item.title === "견적대기"),
      changePreProductStatus: STEP_LIST.findIndex((item) => item.title === "견적승인대기"),
    };

    await getExcelFile(dto);
  };
  const handleChangeOpen = () => {
    setOpen(!open);
  };

  const handleChangeIsCheck = (e: any) => {
    setIsCheck(e.target.checked);
  };

  const handleChangeBrand = (code: string) => {
    setBrandCode(code);
  };

  return (
    <>
      <Button variant="contained" startIcon={<SimCardDownloadOutlinedIcon />} onClick={handleChangeOpen} sx={{ width: "230px" }} size="large">
        대량견적 양식 다운로드
      </Button>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" onClose={handleChangeOpen}>
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: "22px", color: "#333" }}>
          대량견적 양식 다운로드
        </DialogTitle>
        <DialogContent>
          <Stack>
            <Typography>브랜드 선택</Typography>
            <Grid container spacing={2} sx={{ my: 2, userSelect: "none" }}>
              {brandIconOptions
                .filter((v) => !DISABED_BRANDS.includes(v.brandCode))
                .map((item: BrandIconType, i: number) => {
                  return (
                    <Grid item xs={4} sx={{ cursor: "pointer" }} onClick={() => handleChangeBrand(item.brandCode)}>
                      <Stack
                        direction="column"
                        gap={1}
                        key={i}
                        alignItems={"center"}
                        sx={{
                          p: 2,
                          boxShadow: BrandCode !== item.brandCode ? "0px 0px 10px #ccc" : "0px 0px 10px #0665cb",
                          opacity: BrandCode === item.brandCode ? 1 : 0.5,
                          borderRadius: "12px",
                          cursur: "pointer",
                        }}
                      >
                        <img src={item.imagePath} alt={item.brandName} width={80} draggable="false" />
                        <Typography sx={{ display: "inline-block" }}>{item.brandName}</Typography>
                      </Stack>
                    </Grid>
                  );
                })}
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox defaultChecked checked={isCheck} onChange={handleChangeIsCheck} />}
                  label="견적대기 상품을 견적승인대기 상태로 변경하기"
                />
              </Grid>
              {BrandCode === "CS" && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="error">
                    라라페 견적은 대량견적 양식에 브랜드명이 "RTV라라페" 로 표기됩니다. 때문에 대량견적을 넣기 전에 엑셀에서 적절한 브랜드명으로 다시 변경해줘야 합니다.
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  disabled={isPending}
                  fullWidth
                  variant="contained"
                  startIcon={isPending ? <CircularProgress color="inherit" size={"20px"} /> : <SimCardDownloadOutlinedIcon />}
                  onClick={handleGetExcelFile}
                  sx={{ mt: 5, height: "60px" }}
                  size="large"
                >
                  {isPending ? "처리중..." : "대량견적 양식 다운로드"}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        {/* <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleSubmit} autoFocus variant="contained">
            등록
          </Button>
          <Button onClick={handleChangeOpen} variant="outlined">
            취소
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default BulkEstimateTemplateBuilder;
