import { Button, debounce, TextField, Tooltip, Typography } from "@mui/material";
import { getPreProductDto } from "../../../../service/product/types";
import LinkCard from "../LinkCard";
import LinkSubTitle from "../LinkSubTitle";
import { useState, useEffect, useCallback, useMemo } from "react";
import { getSearchTagDto } from "../../../../service/search-tag/types";
import { useGetSearchTag } from "../../../../service/search-tag/searchTagHooks";
import RowStack from "../../../../shared/ui/RowStack";

const MAX_SEARCH_TAG = 5;
const MAX_SEARCH_TAG_LENGTH = 150;
interface Props {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
  tagInfo: getSearchTagDto;
}

const ProductSearchTag: React.FC<Props> = ({ data, onChange, tagInfo }) => {
  const [text, setText] = useState<string>("");
  const { mutateAsync, isPending } = useGetSearchTag((data) => {
    if (text) {
      handleChange(text + ", " + data.join(", "));
    } else {
      handleChange(data.join(", "));
    }
  });

  const recommendSearchTag = () => {
    mutateAsync(tagInfo);
  };

  useEffect(() => {
    setText(data.SearchTag || "");
  }, [data.SearchTag]);

  const debouncedFetchSearchResult = useCallback(
    debounce((value) => {
      onChange((prev: getPreProductDto) => ({ ...prev, SearchTag: value }));
    }, 400),
    []
  );

  const handleChange = (value: string) => {
    setText(value);
    debouncedFetchSearchResult(value);
  };

  const { tagCountError, allError, characterError } = useMemo(() => {
    const tagCountError = text.split(",").length < MAX_SEARCH_TAG;
    const characterError = text.length > MAX_SEARCH_TAG_LENGTH;
    const allError = tagCountError || characterError;
    return { tagCountError, allError, characterError };
  }, [text]);

  return (
    <LinkCard>
      <LinkSubTitle title="검색 태그" required />
      <Typography variant="subtitle2" color="#666">
        <span>검색태그를 최소 5개 입력해주세요. 검색태그는 컴마(,)로 구분됩니다.</span>
      </Typography>
      <RowStack gap={2} sx={{ alignItems: "top" }}>
        <TextField
          value={text}
          multiline={true}
          rows={2}
          onChange={(e) => handleChange(e.target.value)}
          placeholder="ex) 데일리티셔츠, 캐주얼후드티 ..."
          helperText={
            <RowStack justifyContent="start" gap={10}>
              {tagCountError && <Typography variant="body2">키워드를 5개 이상 입력해주세요.</Typography>}
              {characterError && <Typography variant="body2">총길이는 150자 미만으로 작성해주세요.</Typography>}
            </RowStack>
          }
          error={allError}
          fullWidth
        />
        <Tooltip title={<p>ai를 사용하여 검색태그 10개를 자동 생성합니다. </p>} arrow placement="top">
          <Button
            variant="contained"
            onClick={recommendSearchTag}
            disabled={isPending || tagInfo.productName.length < 10}
            sx={{ width: "150px", height: "78px" }}
          >
            {tagInfo.productName.length < 10 ? "상품명을 입력해주세요." : !isPending ? "태그 자동생성" : "로딩 중.."}
          </Button>
        </Tooltip>
      </RowStack>
    </LinkCard>
  );
};

export default ProductSearchTag;
