import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import RowStack from "../../../shared/ui/RowStack";
import { useEffect, useMemo, useState } from "react";
import { useNewGetProductList } from "../../../service/product/productHooks";
import LinearProgress from "@mui/material/LinearProgress";
import noImage from "../../../assets/image/no_image.png";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { brandIconOptions, BrandIconType } from "../../../entities/side-navigation/const/brandIconsOption";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const BASE_URL = process.env.REACT_APP_API_URL;
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_URL;

export const PrintBarCodeLabelModal: React.FC<Props> = ({ open, handleClose }) => {
  const [text, setText] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [cntList, setCntList] = useState<number[]>([]);
  const { data, refetch, isPending } = useNewGetProductList({
    brand: "",
    status: "",
    page: 1,
    page_size: 50,
    sortby: "BarCode",
    direction: "desc",
    searchText,
  });

  useEffect(() => {
    if (data) {
      setCntList(
        data.results.map((_) => {
          return 1;
        })
      );
    }
  }, [data]);

  const handleSearch = () => {
    setSearchText(text);
  };

  const handleChangeCount = (e: any, index: number) => {
    const newCntList = [...cntList];
    newCntList[index] = e.target.value;
    setCntList(newCntList);
  };

  const brandNameMap: { [key: string]: string } = useMemo(() => {
    let result: any = {};

    brandIconOptions.forEach((v: BrandIconType) => {
      result[v.brandCode] = v.brandName;
    });
    return result;
  }, [brandIconOptions]);

  const handleGetLebel = (item: any, index: number) => {
    const repeat = cntList[index];
    const { Color, Size, ProductName_ko, BarCode, Brand } = item as any;
    const brandName = brandNameMap[Brand];

    window.open(
      `https://paperlife.kr/template/print/label.html?color=${Color}&size=${Size}&productName=${ProductName_ko}&barcode=${BarCode}&brand=${brandName}&repeat=${repeat}`,
      "_blank"
    );
  };

  const handleGetCareLebel = (item: any, index: number) => {
    const repeat = cntList[index];
    const { Color, Size, ProductName_ko, BarCode, Brand, FirstSaleSeason, Laundry, CottonInfo_en } = item as any;
    const brandName = brandNameMap[Brand];

    window.open(
      `https://paperlife.kr/template/print/careLabel.html?cottonInfo=${CottonInfo_en}&color=${Color}&size=${Size}&laundry=${Laundry}&productName=${ProductName_ko}&firstSaleSeason=${FirstSaleSeason}&barcode=${BarCode}&brand=${brandName}&repeat=${repeat}`,
      "_blank"
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "1100px",
            maxWidth: "1100px",
            // minWidth: "800px",
            // height: "620px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <RowStack>
          <Typography>라벨 출력</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </RowStack>
      </DialogTitle>
      <DialogContent>
        <RowStack gap={2} sx={{ mb: 2 }}>
          <TextField
            value={text}
            fullWidth
            onChange={(e) => setText(e.target.value.trim())}
            placeholder="바코드나 상품명을 입력해주세요."
            onKeyDown={(e) => {
              e.key === "Enter" && handleSearch();
            }}
          />
          <Button startIcon={<SearchIcon />} sx={{ width: "110px", height: "54px" }} variant="contained" onClick={handleSearch}>
            조회
          </Button>
        </RowStack>
        {searchText && isPending && <LinearProgress />}
        {data && searchText && data.results.length === 0 && <Typography>검색 결과가 없습니다.</Typography>}
        {data && searchText && data.results.length !== 0 && (
          <TableContainer sx={{ height: 400, boxSizing: "border-box", border: "0.5px solid #DFDFDF" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }} align={"left"} style={{ minWidth: 100 }}>
                    이미지
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 100, maxWidth: 200 }}>
                    상품명
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 60 }}>
                    바코드
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 90 }}>
                    수량
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"left"} style={{ minWidth: 140 }}>
                    출력
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.results &&
                  data.results.map((item, i) => {
                    // if (true) {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        <TableCell align={"left"}>
                          <img
                            alt="상품 이미지"
                            src={item?.ImageLink ? `${IMAGE_BASE_URL}/${item.ImageLink[0]}` : noImage}
                            width="100"
                            height="100"
                          />
                        </TableCell>
                        <TableCell align={"center"} width="250">{`${item.ProductName_ko} (${item.Color}, ${item.Size})`}</TableCell>
                        <TableCell align={"center"}>{item.BarCode}</TableCell>
                        <TableCell align={"center"}>
                          <TextField value={cntList[i]} onChange={(e) => handleChangeCount(e, i)} type="number" sx={{ width: "80px" }} />
                        </TableCell>
                        <TableCell align={"center"}>
                          <RowStack gap={2} justifyContent="start">
                            <Button variant="contained" size="large" onClick={() => handleGetLebel(item, i)}>
                              바코드 라벨
                            </Button>
                            <Button variant="contained" size="large" onClick={() => handleGetCareLebel(item, i)}>
                              케어 라벨
                            </Button>
                          </RowStack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      {/* <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleClose} variant="outlined">
          닫기
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};
