import { useCallback, useEffect, useState } from "react";
import { getPreProductDto, updateImageRequestDto } from "../../../../service/product/types";
import LinkCard from "../LinkCard";
import LinkSubTitle from "../LinkSubTitle";
import ImageUpload from "./ImageUpload";
import { useUpdateImage } from "../../../../service/product/productHooks";
import { customToast } from "../../../../shared/lib/customToast";

const defaultURl = process.env.REACT_APP_IMAGE_URL;

interface ProductDetailImageProps {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
}

const ProductDetailImage: React.FC<ProductDetailImageProps> = ({ data, onChange }) => {
  //기존 이미지 파일을 가져와서 여기서 일단동작하게 해야함
  const [preProductSebuImage, setPreProductSebuImage] = useState<string[]>([]);
  const { mutateAsync: updateImage } = useUpdateImage();

  useEffect(() => {
    setPreProductSebuImage(data.ImageLink_sebu?.map((image) => `${defaultURl}/${image}`) || []); //
  }, []);

  const handleChangeImage = async (maxImages: number, acceptedFiles: File[]) => {
    const newImags = [...preProductSebuImage, ...acceptedFiles].slice(-maxImages);
    const dto: updateImageRequestDto = { object_type: "pre_product_sebu", ids: [data.Id.toString()], image: newImags };
    try {
      await updateImage(dto);
      setPreProductSebuImage(() => newImags.map((image) => (typeof image === "string" ? image : URL.createObjectURL(image)))); // 이미지로 전부 변환해서 반환
    } catch (e) {
      customToast("error", "이미지 업로드에 실패했습니다.");
    }
  };

  const handleRemoveImage = () => {
    const newImags = [...preProductSebuImage].slice(0, -1);
    try {
      const dto: updateImageRequestDto = { object_type: "pre_product_sebu", ids: [data.Id.toString()], image: newImags };
      updateImage(dto);
      setPreProductSebuImage((prevImages) => prevImages.slice(0, -1));
    } catch {
      customToast("error", "이미지 삭제에 실패했습니다.");
    }
  };

  return (
    <LinkCard>
      <LinkSubTitle title="라벨, 인포, 상세페이지" required />
      <ImageUpload
        width="200px"
        height="200px"
        maxImages={4}
        images={preProductSebuImage}
        onChange={handleChangeImage}
        onRemoveImage={handleRemoveImage}
      />
    </LinkCard>
  );
};

export default ProductDetailImage;
