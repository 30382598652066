import { brandCodeToBrandNameMap } from "../../entities/side-navigation/const/brandIconsOption";
import { getProductResponseDto } from "../../service/product/types";

export const createLabel = (params: { products: getProductResponseDto[]; barCodeLabelRepeatList: number[]; careLabelRepeatList: number[] }) => {
  const barCodeLabelList = createBarCodeLabel(params.products, params.barCodeLabelRepeatList);
  const careLabelList = createCareLabel(params.products, params.careLabelRepeatList);
  const totalList = [...barCodeLabelList, ...careLabelList];
  const innerText = totalList.map((item) => item).join(createPageBreak());
  const labelHtml = createHtmlForm(innerText);

  const blob = new Blob([labelHtml], { type: "text/html" });
  // Blob URL 생성
  const blobUrl = URL.createObjectURL(blob);
  // 새 탭에서 Blob URL 열기
  const newWindow = window.open(blobUrl, "_blank");

  // Blob URL 해제
  if (newWindow) {
    newWindow.onload = () => {
      URL.revokeObjectURL(blobUrl); // 새 탭이 로드된 후 Blob URL 해제
    };
  } else {
    URL.revokeObjectURL(blobUrl); // 새 탭을 열지 못한 경우 바로 해제
  }
};

//케어라벨 html 배열로 생성
const createBarCodeLabel = (products: getProductResponseDto[], repeat: number[]): string[] => {
  const result: string[] = [];
  for (let i = 0; i < products.length; i++) {
    const repreatCnt = repeat[i];
    const { ProductName_ko, Color, Size, BarCode, Brand } = products[i];
    const BrandName = brandCodeToBrandNameMap[Brand] || "";
    for (let j = 0; j < repreatCnt; j++) {
      result.push(`
         <div class="boxprint" style="padding-top: 10px">
      <span style="position: absolute; right: 5px; bottom: -6px; font-size: 26px">*</span>

      <ul class="printdata">
        <li style="text-align: center; padding: 3px 0 0 0; line-height: 100%">
          <div style="font-size: 12px; width: 150px; word-break: break-all; text-align: left">
          ${ProductName_ko}
          </div>
        </li>
        <li class="text1" style="text-align: left; padding: 10px 0 0 50px; line-height: 150%; font-size: 12px"><b>${Color}</b><br /><b>${Size}</b></li>
        <li style="text-align: center; padding-top: 5px">
          <img
            width="155"
            height="51"
            style="position: relative; left: -10px"
            src="https://barcode.tec-it.com/barcode.ashx?translate-esc=off&data=${BarCode}&code=Code128&multiplebarcodes=false&unit=Fit&dpi=96&imagetype=Gif&rotation=0"
        />
        </li>
        <li class="text1" style="text-align: left; font-size: 12px; position: relative; left: 30px; font-weight: bold; word-break: break-all">
        ${BrandName}
        </li>
      </ul>
    </div>
        `);
    }
  }

  return result;
};

const isExsist = (value: string) => {
  return !value ? `<span style="color:red;">정보 없음</span>` : value;
};

//바코드라벨 html 배열로 생성
const createCareLabel = (products: getProductResponseDto[], repeat: number[]): string[] => {
  const result: string[] = [];
  for (let i = 0; i < products.length; i++) {
    const repreatCnt = repeat[i];
    const { ProductName_ko, Color, Size, CottonInfo_ko, Brand, FirstSaleSeason, Laundry } = products[i];
    const BrandName = brandCodeToBrandNameMap[Brand] || "";
    for (let j = 0; j < repreatCnt; j++) {
      result.push(`
              <div class="boxprint0">
          <ul class="printdata_1" style="top: -54px; left: -15px">
            <li
              class="fonts"
              style="position: relative; top: -15px; width: 158px; line-height: 120%; text-align: center; font-size: 10px; font-weight: bold; margin-bottom: 5px;"
            >
              ${isExsist(BrandName)}
            </li>
            <li
              class="fonts"
              style="position: relative; top: -15px; width: 158px; line-height: 120%; font-weight: bold; margin-bottom: 3px; font-size: 10px;"
            >
              [수입상품 품질 표시사항]
            </li>
            <li class="fonts" style="position: relative; top: -15px; width: 158px; line-height: 120%; display: flex; font-size: 10px;">
              <div style="white-space: nowrap">모델명:</div>
              <div style="margin-left: 4px;">${isExsist(ProductName_ko)}</div>
            </li>
            <li class="fonts" style="position: relative; top: -15px; width: 158px; line-height: 120%; font-size: 10px;">
              옵션명: ${isExsist(Color)} ${isExsist(Size)}
            </li>
            <li class="fonts" style="position: relative; top: -15px; width: 158px; line-height: 120%; font-size: 10px;">
              소재: ${isExsist(CottonInfo_ko)}
            </li>
            <li class="fonts" style="position: relative; top: -15px; width: 158px; line-height: 120%; font-size: 10px;">
              최초판매시즌: ${isExsist(FirstSaleSeason)}
            </li>
            <li class="fonts" style="position: relative; top: -15px; width: 158px; line-height: 120%; font-size: 10px;">
              수입자명: ${isExsist(BrandName)}
            </li>
            <li class="fonts" style="position: relative; top: -15px; width: 158px; line-height: 120%; font-size: 10px;">
              취급상 주의사항: ${isExsist(Laundry)}
            </li>
            <li class="fonts" style="position: relative; top: -15px; width: 158px; line-height: 120%; font-size: 10px;">
            표시자 주소(연락처): 경기도 부천 조마루로 427번길 106 4층 (032-710-0992)
            </li>
          </ul>
        </div>
            `);
    }
  }

  return result;
};

//라벨 중간에 들어가는것 => 한번에 출력할 수 있게 모든 페이지에서 보이는게 좋을까? 아니면 페이지별로 나눠서 보이는게 좋을까?
function createPageBreak() {
  return `<div style="page-break-after: always"></div>`;
}

/**
 *
 * 1. css 결합
 * 2. 기본 페이지 생성
 * 3. 라벨에 따라 생
 */

const createHtmlForm = (innerText: string) => {
  return `
<html>
  <head>
    <meta charset="utf-8" />
    <style type="text/css">
      body {
        background: #fff;
        /* font-family: "微软雅黑", Verdana, Arial; */
        line-height: 150%;
        margin: 0;
        padding: 0;
        color: #404040;
      }
      div {
        margin: 0 auto;
        padding: 0;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul,
      li,
      dl,
      dt,
      dd,
      form,
      img,
      p {
        margin: 0;
        padding: 0;
        border: none;
        list-style-type: none;
      }
      .boxprint {
        width: 250px;
        height: 170px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
      }
      .boxprint li {
        text-align: left;
        padding-left: 10px;
        line-height: 120%;
      }
      .boxprint .text {
        font-size: 9px;
      }
      .boxprint .text1 {
        font-size: 9px;
      }
      .boxprint .text1 b {
        font-size: 14px;
      }
      .boxprint .printdata {
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      
      .boxprint0 {
        width: 270px;
        height: 180px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
      }
      .boxprint0 li {
        line-height: 150%;
      }
      .boxprint0 .fonts {
        font-size: 12px;
      }
      .boxprint0 .printdata_1 {
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        position: relative;
        top: -70px;
        width: 100%;
        height: 100%;
      }

      .printdata2 {
        position: absolute;
        top: 75px;
        right: -50px;
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        font-size: 12px;
        font-weight: bold;
        line-height: 120%;
      }
      .boxprint0 .printdata0 {
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        position: relative;
        top: -60px;
        left: -20px;
        width: 100%;
        height: 100%;
      }
      .boxprint0 .stockup0 {
        font-size: 12px;
        position: absolute; /* right: 70px; */
        font-weight: bold;
        border: 1px solid #000;
        display: inline-block;
        height: 20px;
        line-height: 20px;
        top: 50%;
        margin-top: 35px;
        margin-left: 80px;
        padding-left: 4px;
        padding-right: 4px;
      }
      .boxprint0 .fonts1 {
        font-size: 11px;
      }
      ._boxprint {
        width: 250px;
        height: 170px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
      }
      ._boxprint li {
        text-align: left;
        padding-left: 10px;
        line-height: 120%;
      }
      ._boxprint .text {
        font-size: 9px;
      }
      ._boxprint ._text1 {
        font-size: 9px;
      }
      ._boxprint ._text1 b {
        font-size: 14px;
      }
      ._boxprint ._printdata {
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        position: relative;
        top: -50px;
        left: -40px;
        width: 100%;
        height: 100%;
      }
      ._boxprint ._printdata_1 {
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        position: relative;
        top: 3px;
      }
      ._printdata2 {
        position: absolute;
        top: 75px;
        right: -50px;
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        font-size: 12px;
        font-weight: bold;
        line-height: 120%;
      }
      ._boxprint ._stockup {
        font-size: 12px;
        position: absolute; /* right: 70px; */
        font-weight: bold;
        border: 1px solid #000;
        display: inline-block;
        height: 20px;
        line-height: 20px;
        top: 50%;
        margin-top: 0;
        margin-left: -38px;
        padding-left: 2px;
        padding-right: 2px;
      }
      .always {
        page-break-after: always;
        /* display:none; */
      }

      .always:last-child {
        page-break-after: auto !important;
        /* display:none; */
      }
      .boxprintcus {
        /* border: #000000 1px solid; */
        width: 260px;
        height: 175px;
        margin: 5px auto 0 auto;
        overflow: hidden;
        position: relative;
      }
      .boxprintcus .text span {
        font-size: 9px;
        zoom: 0.9;
      }
      .boxprintcus_1 {
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        width: 175px;
        height: 260px;
        overflow: hidden;
        position: absolute;
        right: 41px;
        top: -44px;
      }
      .boxprintcus li {
        text-align: left;
        line-height: 100%;
      }
    </style>
  </head>
  <body>
  ${innerText}
    <div
      class="glasp-extension-toaster"
      style="
        display: block;
        width: 320px;
        margin: unset;
        padding: unset;
        border: unset;
        border-radius: unset;
        outline: unset;
        background-color: unset;
        box-shadow: unset;
        position: fixed;
        top: 40px;
        right: 24px;
        z-index: 9999;
      "
    ></div>
    <div
      class="glasp-extension"
      style="
        display: block;
        width: unset;
        margin: unset;
        padding: unset;
        border: unset;
        border-radius: unset;
        outline: unset;
        background-color: unset;
        box-shadow: unset;
        position: fixed;
        bottom: 16px;
        right: 16px;
        z-index: 9999;
      "
    ></div>
  </body>
  </html>`;
};
