import {
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import LinkSubTitle from "../LinkSubTitle";
import { getPreProductDto } from "../../../../service/product/types";
import LinkCard from "../LinkCard";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";

type fiberType = {
  name: string;
  value: number;
};

interface Props {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
}

const NewFiberCompositionForm: React.FC<Props> = ({ data, onChange }) => {
  const [text, setText] = useState<string>("");
  const [fiberList, setFiberList] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios.get("https://www.atterlic.com/v1/api/fiber").then((res) => {
        setFiberList(res.data);
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!fiberList) return;
    if (data.CottonInfo_D8?.length === 0) {
      onChange((prev) => ({ ...prev, CottonInfo_ko: "", CottonInfo_en: "" }));
    } else {
      const CottonInfo_ko_list: string[] = [];
      const CottonInfo_en_list: string[] = [];

      data.CottonInfo_D8?.forEach((fiber: fiberType, i: number) => {
        const fiber_info = fiberList.find((v) => v.name_D8 === fiber.name);

        if (fiber_info) {
          CottonInfo_ko_list.push(fiber_info.name_kr + `${fiber.value}%`);
          CottonInfo_en_list.push(fiber_info.name_en + `${fiber.value}%`);
        }
      });

      onChange((prev) => ({ ...prev, CottonInfo_ko: CottonInfo_ko_list.join(" ,"), CottonInfo_en: CottonInfo_en_list.join(" ,") }));
    }
  }, [data.CottonInfo_D8, fiberList]);

  const handleAddFiber = (fiberName: string) => {
    const item = { name: fiberName, value: 0 };
    setText("");
    if (data.CottonInfo_D8?.find((v: fiberType) => v.name === item.name)) return;
    onChange((prev) => ({ ...prev, CottonInfo_D8: [...(prev?.CottonInfo_D8 || []), item] }));
  };

  const handleRemoveFiber = (index: number) => {
    onChange((prev) => ({ ...prev, CottonInfo_D8: prev?.CottonInfo_D8?.filter((v, i) => i !== index) }));
  };

  const handleChhangeFiberValue = (index: number, value: number) => {
    onChange((prev) => ({
      ...prev,
      CottonInfo_D8: prev?.CottonInfo_D8?.map((v, i) => (i === index ? { ...v, value: isNaN(Number(value)) ? 0 : Number(value) } : v)),
    }));
  };

  const matchFiberList = useMemo(() => {
    return fiberList.filter(
      (v) => text !== "" && (v.name_D8.includes(text) || v.name_kr.includes(text) || v.name_en.toLowerCase().includes(text.toLowerCase()))
    );
  }, [text]);

  const fiberMap = useMemo(() => {
    return fiberList.reduce((acc, cur) => {
      acc[cur.name_D8] = `${cur.name_kr} / ${cur.name_en}`;
      return acc;
    }, {});
  }, [fiberList]);

  const handleSelectFiber = (e: any) => {
    if (e.key === "Enter") {
      if (matchFiberList.length === 0) return;
      handleAddFiber(matchFiberList[0].name_D8);
    }
  };

  const totalValue = useMemo(() => {
    return data.CottonInfo_D8?.reduce((acc, cur) => acc + cur.value, 0) || 0;
  }, [data.CottonInfo_D8]);

  return (
    <LinkCard>
      <LinkSubTitle title="섬유의 조성" required />

      <Stack sx={{ position: "relative" }} gap={2}>
        <TextField
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleSelectFiber}
          placeholder="섬유를 검색하세요."
          label="섬유검색"
        />
        {matchFiberList.length !== 0 && (
          <List
            sx={{ position: "absolute", top: "50px", left: 0, width: "100%", backgroundColor: "white", zIndex: 100, boxShadow: 2, borderRadius: 4 }}
          >
            {matchFiberList.map((v, i) => {
              const isExist = data.CottonInfo_D8?.find((fiber: fiberType) => fiber.name === v.name_D8);
              return (
                <ListItemButton key={i} onClick={() => handleAddFiber(v.name_D8)}>
                  <ListItemIcon>{isExist ? <CheckIcon /> : <AddIcon />}</ListItemIcon>
                  <ListItemText sx={{ textDecoration: isExist ? "line-through" : "" }}>{`${v.name_kr} (${v.name_en})`}</ListItemText>
                </ListItemButton>
              );
            })}
          </List>
        )}

        {data?.CottonInfo_D8?.length !== 0 && (
          <List sx={{ width: "100%" }}>
            {data?.CottonInfo_D8?.map((v, i) => (
              <>
                <ListItem key={i}>
                  <ListItemText>
                    <Typography variant="caption" color={"#666"} sx={{ mr: 3 }}>
                      {i + 1}.{" "}
                    </Typography>{" "}
                    {fiberMap[v.name]}
                  </ListItemText>
                  <TextField
                    size="small"
                    value={v.value}
                    onChange={(e) => handleChhangeFiberValue(i, parseInt(e.target.value))}
                    sx={{ width: "150px", mr: "50px" }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                  <IconButton onClick={() => handleRemoveFiber(i)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </ListItem>
              </>
            ))}
          </List>
        )}
        <Divider />
        <ListItem>
          <ListItemText sx={{ mr: "auto" }}></ListItemText>
          <Typography fontWeight={totalValue === 100 ? "bold" : ""} sx={{ color: totalValue === 100 ? "primary.main" : "error.main" }}>
            총합 {totalValue} (%)
          </Typography>
        </ListItem>
      </Stack>
    </LinkCard>
  );
};

export default NewFiberCompositionForm;
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
