import React from "react";
import { Button, Card, Stack, TextField, Typography } from "@mui/material";
import "react-data-grid/lib/styles.css";
import {
  useCreateProductAtDome88,
  useGetBulkEstimateExcelFile,
  useGetProductStatus,
  useTranslate,
  useUpdatePreProduct,
} from "../service/product/productHooks";
import { useCreateBarcodeAlert } from "../service/barcode-alert/barcodeAlertHooks";
import { useCreateBasket } from "../service/basket/BasketHooks";
import { StatusBadge } from "../entities/product/style/StatusBadge";
import BackDropLoading from "../shared/ui/loading/BackDropLoading";
import { searchTagApi } from "../service/search-tag/searchTagAxios";
import { copyToClicpboard } from "../entities/rocket/lib/copyToClipBoard";
import { STEP_LIST } from "../entities/link/const/stepList";
import axios from "axios";
import { filberlist } from "./response";
import { getProductStatusDto } from "../service/product/types";
import { findPath } from "../entities/link/lib/findPath";

const list = [["barcode", 1, 7521011]];

const Test: React.FC = () => {
  const { mutateAsync: createBasket } = useCreateBasket();
  const { mutate: translate } = useTranslate();
  const { mutateAsync: getProductStatus } = useGetProductStatus();

  const handleGetProductStatus = async () => {
    const dto: getProductStatusDto = {
      barCode: ["AT25011001312"],
      brand: "AT",
    };
    await getProductStatus(dto);
  };

  const handleTranslate = async () => {
    translate({ text: "안녕하세요", source: "ko", target: "en" });
  };

  const handleCreateBasket = async () => {
    for (let i = 621100; i <= 621400; i++) {
      await createBasket({ BasketID: i.toString(), IsCoupang: false, CoupangBaskID: "" });
    }
  };
  const { mutateAsync: getExcelFile, isPending } = useGetBulkEstimateExcelFile();

  const handleGetExcelFile = async () => {
    await getExcelFile({ Brand: "PA", ischnagePreProductStatus: 1, targetPreProductStatus: 6, changePreProductStatus: 6 });
  };

  const { mutate: createBarcodeAlert } = useCreateBarcodeAlert();

  const { mutateAsync: updatePreProduct } = useUpdatePreProduct();

  function handleFileUpload(event: any) {
    const file = event.target.files[0]; // 업로드된 파일 가져오기
    if (!file) {
      alert("파일을 선택해주세요.");
      return;
    }

    const reader = new FileReader();

    reader.onload = async function (e: any) {
      const text = e.target.result; // 파일 내용을 텍스트로 읽음
      const csvData = parseCSV(text); // CSV 파싱
      // console.log(csvData);

      // 1줄 씩 파싱

      for (let i = 480; i < csvData.length; i++) {
        const [Id, productName, options, sizes] = csvData[i];

        const searchTag: any = await searchTagApi.getSearchTag({ productName, options, sizes });
        // console.log(searchTag.join(", "));
        console.log(`${i}/${csvData.length - 1} : ${productName}   (${searchTag.join(", ")})`);

        await updatePreProduct({ Id: parseInt(Id), SearchTag: searchTag.join(", ") as string } as any);
      }
    };

    reader.onerror = function () {
      alert("파일을 읽는 중 오류가 발생했습니다.");
    };

    reader.readAsText(file); // 파일을 텍스트로 읽기
  }

  const [text, setText] = React.useState("");
  function generateJsonPaths() {
    const basePath = "pre_products_sebu/ME/2501/";
    /**
     *
     *
     * 주어진 파일명 문자열을 JSON 형식의 배열로 변환하는 함수
     */
    // 파일명을 줄 단위로 분리하여 배열 생성
    const fileList = text.split(" ").map((t) => t.trim());

    // 파일명 앞에 basePath 추가
    const fullPaths = fileList.map((filename) => basePath + filename);

    // JSON 변환
    return JSON.stringify(fullPaths);
  }

  // CSV 파싱 함수
  function parseCSV(text: string) {
    const rows = text.split("\n"); // 줄 단위로 분리
    return rows.slice(1).map((row) => {
      const regex = /(".*?"|[^",\s]+)(?=\s*,|\s*$)/g; // 쉼표와 따옴표 처리
      const cells = [];
      let match;
      while ((match = regex.exec(row))) {
        cells.push(match[1].replace(/^"|"$/g, "")); // 따옴표 제거
      }
      return cells;
    });
  }

  const { mutateAsync: createDome88Product } = useCreateProductAtDome88();
  const handleCreateD8Product = async () => {
    const error = [];
    for (let i = 0; i < a.length; i++) {
      try {
        await createDome88Product(a[i]);
      } catch {
        console.log(a[i]);
        error.push(a[i]);
      }
    }

    console.log(error);
  };

  return (
    <Stack gap={3}>
      <Stack gap={3}>
        <TextField sx={{ backgroundColor: "white", mt: "100px" }} value={text} onChange={(e) => setText(e.target.value)} />
        <Typography variant="h5">{generateJsonPaths()}</Typography>
        <Button
          variant="contained"
          onClick={() => {
            copyToClicpboard(generateJsonPaths());
            setText("");
          }}
        >
          copy
        </Button>
      </Stack>
      <BackDropLoading open={isPending} />
      <Card sx={{ mt: 10, width: "400xp", margin: "100px auto" }}>
        <Stack gap={5} alignItems={"center"}>
          {STEP_LIST.map((step, index) => (
            <StatusBadge color={step.color} backgroundColor={step.backgroundColor}>
              {step.title}
            </StatusBadge>
          ))}
          <Button onClick={handleGetExcelFile}>페이퍼먼츠 견적 할거 다운로드 </Button>

          <input type="file" onChange={handleFileUpload} />
          <Button onClick={handleCreateD8Product}>도8에 상품생성</Button>
          <Button onClick={handleGetProductStatus}>상품정보 가져오기 </Button>
          <button onClick={handleCreateBasket}>바구니 만들기 </button>
        </Stack>
      </Card>
    </Stack>
  );
};

export default Test;

const a = [
  
  {
      "preproduct_id": 71556
  },
  {
      "preproduct_id": 71663
  },
  {
      "preproduct_id": 71696
  },
  {
      "preproduct_id": 71710
  },
  {
      "preproduct_id": 71714
  },
  {
      "preproduct_id": 71715
  },
  {
      "preproduct_id": 71717
  },
  {
      "preproduct_id": 71719
  },
  {
      "preproduct_id": 71722
  },
  {
      "preproduct_id": 71725
  },
  {
      "preproduct_id": 71728
  },
  {
      "preproduct_id": 71730
  },
  {
      "preproduct_id": 71733
  },
  {
      "preproduct_id": 71764
  },
  {
      "preproduct_id": 71768
  },
  {
      "preproduct_id": 71811
  },
  {
      "preproduct_id": 71820
  },
  {
      "preproduct_id": 71851
  },
  {
      "preproduct_id": 71921
  },
  {
      "preproduct_id": 71938
  },
  {
      "preproduct_id": 72028
  },
  {
      "preproduct_id": 72084
  },
  {
      "preproduct_id": 72130
  },
  {
      "preproduct_id": 72209
  },
  {
      "preproduct_id": 72412
  },
  {
      "preproduct_id": 72469
  }
]