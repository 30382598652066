import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
  FormControl,
  TextField,
  InputAdornment,
  Divider,
  Chip,
  Button,
  Box,
  Dialog,
  DialogActions,
} from "@mui/material";
import IOSSwitch from "../IOSSwitch";
import LinkCard from "../LinkCard";
import LinkSubTitle from "../LinkSubTitle";
import React, { useEffect, useState } from "react";
import RowStack from "../../../../shared/ui/RowStack";
import { getOriginalProductResponseDto, updateImageRequestDto } from "../../../../service/product/types";
import ImageUpload from "./ImageUpload";
import { calcCoupangMargin, calcOurMargin, calcQuotedPrice, calcTotalCost } from "../../lib/priceCalcfunctions";
import _ from "lodash";
import { useCreateBarCode, useUpdateImage } from "../../../../service/product/productHooks";
import { customToast } from "../../../../shared/lib/customToast";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { cnToKrShoeSizeMap } from "../../const/shoesSizeMap";
import { customAxios } from "../../../../config/axios-config";

// ProductOptionsForm에서 사용하는 이미지 타입
type ImageType = {
  [key: string]: {
    mainImage: string[];
    additinalImage: string[];
  };
};

interface Props {
  preProductID: number;
  data: getOriginalProductResponseDto[];
  Brand: string;
  onChange: React.Dispatch<React.SetStateAction<getOriginalProductResponseDto[]>>;
}

const ImageUrl = process.env.REACT_APP_IMAGE_URL;
const COUPANGE_COMMISSION = 40; //쿠팡커미션
const OUR_COMMISSION = 10.5; // 자사 커미션

const ProductOptionsForm: React.FC<Props> = ({ data, onChange, Brand, preProductID }) => {
  const [memoOpenList, setMemoOpenList] = useState<number[]>([]);
  const [IsSameMemo, setIsSameMemo] = useState(true);
  const [IsSamePrice, setIsSamePrice] = useState(true);
  const [coupangeCommission, setCoupangeCommission] = useState<number[]>([]);
  const [ourCommission, setOurCommision] = useState<number[]>([]);
  const [Images, setImages] = useState<ImageType>({}); // null이 아니면 .. 이미지가 있는것
  const { mutateAsync: updateImage } = useUpdateImage();
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    // data.products가 변경될때마다 이미지 객체를 초기화
    setImages(() => {
      return data.reduce((acc, cur, i) => {
        acc[cur.BarCode as string] = {
          mainImage: cur.ImageLink?.map((image) => `${ImageUrl}/${image}`) || [],
          additinalImage: cur.ImageLink_add?.map((image) => `${ImageUrl}/${image}`) || [],
        };
        return acc;
      }, {} as ImageType);
    });

    if (data.length !== coupangeCommission.length) {
      setOurCommision(data.map(() => OUR_COMMISSION));
      setCoupangeCommission(data.map(() => COUPANGE_COMMISSION));
    }
  }, []);

  //추가이미지
  const handleChangeAddImage = (maxImages: number, acceptedFiles: File[], index?: number) => {
    index = index || 0;
    const { Color_zh, BarCode } = data[index];
    const BarCodeList = data.filter((product) => product.Color_zh === Color_zh).map((product) => product.BarCode);
    const newImages = [...Images[BarCode].additinalImage, ...acceptedFiles].slice(-maxImages);

    // back에 이미지 업로드
    try {
      const dto: updateImageRequestDto = { object_type: "product_add", ids: BarCodeList, image: newImages };
      updateImage(dto);

      // front에 이미지 업로드
      const newImagesObject = _.cloneDeep(Images);
      BarCodeList.forEach((BarCode) => {
        newImagesObject[BarCode].additinalImage = newImages.map((image) => (typeof image === "string" ? image : URL.createObjectURL(image)));
      });
      setImages(newImagesObject);
    } catch (e) {
      customToast("error", "이미지 업로드에 실패했습니다.");
    }
  };

  //메인 이미지
  const handleChangeMainImage = async (maxImages: number, acceptedFiles: File[], index?: number) => {
    index = index || 0;
    const { Color_zh, BarCode } = data[index];
    const BarCodeList = data.filter((product) => product.Color_zh === Color_zh).map((product) => product.BarCode);
    const newImages = [...Images[BarCode].mainImage, ...acceptedFiles].slice(-maxImages);

    // back에 이미지 업로드
    try {
      const dto: updateImageRequestDto = { object_type: "product", ids: BarCodeList, image: newImages };
      await updateImage(dto);

      // front에 이미지 업로드
      const newImagesObject = _.cloneDeep(Images);
      BarCodeList.forEach((BarCode) => {
        newImagesObject[BarCode].mainImage = newImages.map((image) => (typeof image === "string" ? image : URL.createObjectURL(image)));
      });
      setImages(newImagesObject);
    } catch (e) {
      customToast("error", "이미지 업로드에 실패했습니다.");
    }
  };

  //추가이미지 삭제
  const handleRemoveAddImage = (index?: number) => {
    index = index || 0;
    try {
      const { Color_zh, BarCode } = data[index];
      const BarCodeList = data.filter((product) => product.Color_zh === Color_zh).map((product) => product.BarCode);
      const newImages = [...Images[BarCode].additinalImage].slice(0, -1);

      // back에 이미지 업로드
      const dto = { object_type: "product_add", ids: BarCodeList, image: newImages };
      updateImage(dto);

      // front에 이미지 업로드
      const newImagesObject = _.cloneDeep(Images);
      BarCodeList.forEach((BarCode) => {
        newImagesObject[BarCode].additinalImage = newImagesObject[BarCode].additinalImage.slice(0, -1); // 사실 이렇게 까지 map으로 할 필요는 없음
      });
      setImages(newImagesObject);
    } catch (e) {
      customToast("error", "이미지 업로드에 실패했습니다.");
    }
  };

  //메인 이미지 삭제
  const handleRemoveMainImage = (index?: number) => {
    index = index || 0;
    try {
      const { Color_zh, BarCode } = data[index];
      const BarCodeList = data.filter((product) => product.Color_zh === Color_zh).map((product) => product.BarCode);
      const newImages = [...Images[BarCode].mainImage].slice(0, -1);

      // back에 이미지 업로드
      const dto = { object_type: "product", ids: BarCodeList, image: newImages };
      updateImage(dto);

      // front에 이미지 업로드
      const newImagesObject = _.cloneDeep(Images);
      BarCodeList.forEach((BarCode) => {
        newImagesObject[BarCode].mainImage = newImagesObject[BarCode].mainImage.slice(0, -1); // 사실 이렇게 까지 map으로 할 필요는 없음
      });
      setImages(newImagesObject);
    } catch (e) {
      customToast("error", "이미지 업로드에 실패했습니다.");
    }
  };

  const handleChangeMemoOpenList = (index: number) => {
    if (memoOpenList.includes(index)) {
      setMemoOpenList(memoOpenList.filter((v) => v !== index));
    } else {
      setMemoOpenList([...memoOpenList, index]);
    }
  };

  // 메모 일괄 적용
  const handleIsSameMemo = () => {
    setIsSameMemo(!IsSameMemo);
  };

  // 가격 일괄 적용
  const handleIsSamePrice = () => {
    setIsSamePrice(!IsSamePrice);
  };

  //컬러 변경
  const handleChangeColor = (Color_zh: string, index: number, value: string) => {
    if (Color_zh) {
      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product) => {
          return product.Color_zh === Color_zh ? { ...product, Color: value } : product;
        });
      });
    } else {
      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product, i) => {
          return i === index ? { ...product, Color: value } : product;
        });
      });
    }
  };

  //사이즈 변경
  const handleChangeSize = (Size_zh: string, index: number, value: string) => {
    if (Size_zh) {
      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product) => {
          return product.Size_zh === Size_zh ? { ...product, Size: value } : product;
        });
      });
    } else {
      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product, i) => {
          return i === index ? { ...product, Size: value } : product;
        });
      });
    }
  };

  //한국 최저가 검색 변경
  const handleChangeKoreaCost = (index: number, value: number) => {
    if (isNaN(value)) return;

    if (IsSamePrice) {
      onChange((pev: getOriginalProductResponseDto[]) => {
        return pev.map((product) => {
          return {
            ...product,
            KoreaCost: value,
          };
        });
      });
    } else {
      // 하나만 변경되게 하기

      onChange((pev: getOriginalProductResponseDto[]) => {
        return pev.map((product, i) => {
          return index === i
            ? {
                ...product,
                KoreaCost: value,
              }
            : product;
        });
      });
    }
  };

  //사이즈 변경
  const handleChangeProductByIndex = (index: number, key: keyof getOriginalProductResponseDto, value: any) => {
    onChange((prev: getOriginalProductResponseDto[]) => {
      return prev.map((product, i) => {
        return i === index ? { ...product, [key]: value } : product;
      });
    });
  };

  //자사 수수료 변경
  const handleChangeOurCommission = (index: number, value: number) => {
    if (isNaN(value)) return;

    if (IsSamePrice) {
      setOurCommision(ourCommission.map((_, i) => value));
      onChange((pev: getOriginalProductResponseDto[]) => {
        return pev.map((product, index) => {
          return {
            ...product,
            OurMargin: calcOurMargin(product.OurSalePrice, value, product.TotalCost),
          };
        });
      });
    } else {
      setOurCommision((prev) => {
        const newCommission = [...prev];
        newCommission[index] = value;
        return newCommission;
      });
      onChange((pev: getOriginalProductResponseDto[]) => {
        return pev.map((product, index) => {
          return index === index
            ? {
                ...product,
                OurMargin: calcOurMargin(product.OurSalePrice, value, product.TotalCost),
              }
            : product;
        });
      });
    }
  };

  //중국 원가 변경
  const handleChangeChinaCost = (index: number, value: number) => {
    if (isNaN(value)) return;

    if (IsSamePrice) {
      onChange((pev: getOriginalProductResponseDto[]) => {
        return pev.map((product) => {
          const TotalCost = calcTotalCost(value, product.LocalDeliveryFee);
          const QuotedPrice = calcQuotedPrice(product.SalePrice, coupangeCommission[0]);
          const OurMargin = calcOurMargin(product.OurSalePrice, ourCommission[0], TotalCost);
          const RocketMargin = calcCoupangMargin(QuotedPrice, TotalCost);
          return {
            ...product,
            ChinaCost: value,
            TotalCost,
            QuotedPrice,
            OurMargin,
            RocketMargin,
          };
        });
      });
    } else {
      // 하나만 변경되게 하기

      onChange((pev: getOriginalProductResponseDto[]) => {
        return pev.map((product, i) => {
          if (index === i) {
            const TotalCost = calcTotalCost(value, product.LocalDeliveryFee);
            const QuotedPrice = calcQuotedPrice(product.SalePrice, coupangeCommission[0]);
            const OurMargin = calcOurMargin(product.OurSalePrice, ourCommission[0], TotalCost);
            const RocketMargin = calcCoupangMargin(QuotedPrice, TotalCost);
            return {
              ...product,
              ChinaCost: value,
              TotalCost,
              QuotedPrice,
              OurMargin,
              RocketMargin,
            };
          } else {
            return product;
          }
        });
      });
    }
  };

  // 현지 배송비 변경
  const handleChangeLocalDeiiveryFee = (index: number, value: number) => {
    if (isNaN(value)) return;

    if (IsSamePrice) {
      onChange((pev: getOriginalProductResponseDto[]) => {
        return pev.map((product) => {
          const TotalCost = calcTotalCost(product.ChinaCost, value);
          const QuotedPrice = calcQuotedPrice(product.SalePrice, coupangeCommission[0]);
          const OurMargin = calcOurMargin(product.OurSalePrice, ourCommission[0], TotalCost);
          const RocketMargin = calcCoupangMargin(QuotedPrice, TotalCost);
          return {
            ...product,
            LocalDeliveryFee: value,
            TotalCost,
            QuotedPrice,
            OurMargin,
            RocketMargin,
          };
        });
      });
    } else {
      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product, i) => {
          if (index === i) {
            const TotalCost = calcTotalCost(product.ChinaCost, value);
            const QuotedPrice = calcQuotedPrice(product.SalePrice, coupangeCommission[0]);
            const OurMargin = calcOurMargin(product.OurSalePrice, ourCommission[0], TotalCost);
            const RocketMargin = calcCoupangMargin(QuotedPrice, TotalCost);
            return {
              ...product,
              LocalDeliveryFee: value,
              TotalCost,
              QuotedPrice,
              OurMargin,
              RocketMargin,
            };
          } else {
            return product;
          }
        });
      });
    }
  };

  // 자사판매가 변경
  const handleChangeOurSalePrice = (index: number, value: number) => {
    if (isNaN(value)) return;

    if (IsSamePrice) {
      onChange((perv: getOriginalProductResponseDto[]) => {
        return perv.map((product) => {
          return {
            ...product,
            OurSalePrice: value,
            OurMargin: calcOurMargin(value, ourCommission[0], product.TotalCost),
          };
        });
      });
    } else {
      // 하나만 변경되게 하기

      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product, i) => {
          return index === i
            ? {
                ...product,
                OurSalePrice: value,
                OurMargin: calcOurMargin(value, ourCommission[index], product.TotalCost),
              }
            : product;
        });
      });
    }
  };

  // 쿠팡 판매가 변경
  const hadleChangeCoupangSalePrice = (index: number, value: number) => {
    if (isNaN(value)) return;

    if (IsSamePrice) {
      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product) => {
          const newQuotiedPrice = calcQuotedPrice(value, coupangeCommission[0]);
          return {
            ...product,
            SalePrice: value,
            QuotedPrice: newQuotiedPrice,
            RocketMargin: calcCoupangMargin(newQuotiedPrice, product.TotalCost),
          };
        });
      });
    } else {
      onChange((prev: getOriginalProductResponseDto[]) => {
        const newQuotiedPrice = calcQuotedPrice(value, coupangeCommission[0]);
        return prev.map((product, i) => {
          return i == index
            ? {
                ...product,
                SalePrice: value,
                QuotedPrice: newQuotiedPrice,
                RocketMargin: calcCoupangMargin(newQuotiedPrice, product.TotalCost),
              }
            : product;
        });
      });
    }
  };

  //쿠팡 수수료 변경
  const handleChangeCoupangeCommission = (index: number, value: number) => {
    if (isNaN(value)) return;

    if (IsSamePrice) {
      setCoupangeCommission(coupangeCommission.map((_, i) => value));

      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product) => {
          return {
            ...product,
            QuotedPrice: calcQuotedPrice(product.SalePrice, value),
            RocketMargin: calcCoupangMargin(product.QuotedPrice, product.TotalCost),
          };
        });
      });
    } else {
      setCoupangeCommission((prev) => {
        const newCommission = [...prev];
        newCommission[index] = value;
        return newCommission;
      });
      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product, i) => {
          return i === index
            ? {
                ...product,
                QuotedPrice: calcQuotedPrice(product.SalePrice, value),
                RocketMargin: calcCoupangMargin(product.QuotedPrice, product.TotalCost),
              }
            : product;
        });
      });
    }
  };

  // 메모 변경
  const handleChangeMemo = (index: number, e: any) => {
    const { name, value } = e.target;

    if (IsSameMemo) {
      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product) => {
          return {
            ...product,
            [name]: value,
          };
        });
      });
    } else {
      onChange((prev: getOriginalProductResponseDto[]) => {
        return prev.map((product, i) => {
          return i === index ? { ...product, [name]: parseInt(e.target.value) } : product;
        });
      });
    }
  };

  //사용가능 여부 변경
  const handleChangeIsUse = (Color_zh: string, BarCode: string, value: boolean) => {
    // onChange((prev: getOriginalProductResponseDto[]) => {
    //   return prev.map((product, i) => {
    //     return product.Color_zh === Color_zh ? { ...product, IsUse: value } : product;
    //   });
    // });

    onChange((prev: getOriginalProductResponseDto[]) => {
      return prev.map((product, i) => {
        return product.BarCode === BarCode ? { ...product, IsUse: value } : product;
      });
    });
  };

  const handleChangeAllIsUse = (type: boolean) => {
    const newProducts = data.map((product) => {
      return { ...product, IsUse: type };
    });
    onChange(newProducts);
  };

  const handleChangeShoesSizeCnToKr = () => {
    onChange((prev: getOriginalProductResponseDto[]) => {
      return prev.map((product) => {
        return {
          ...product,
          Size: cnToKrShoeSizeMap(product.Size) || product.Size,
        };
      });
    });
  };

  return (
    <LinkCard>
      <RowStack>
        <LinkSubTitle title={`옵션 리스트`} required />
        {isHide ? (
          <Button onClick={() => setIsHide(false)} startIcon={<KeyboardArrowDownIcon />}>
            펼치기
          </Button>
        ) : (
          <Button onClick={() => setIsHide(true)} startIcon={<KeyboardArrowUpIcon />}>
            숨기기
          </Button>
        )}
      </RowStack>

      {!isHide && (
        <>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={IsSamePrice} onChange={handleIsSamePrice} />}
              label={<Typography fontSize="14px">가격 정보 일괄 적용</Typography>}
            />
            <FormControlLabel
              control={<Checkbox checked={IsSameMemo} onChange={handleIsSameMemo} />}
              label={<Typography fontSize="14px">메모 정보 일괄 적용</Typography>}
            />
          </FormGroup>
          <Stack gap={2} border={"1px solid"} borderRadius={2} p={5}>
            <RowStack gap={2} justifyContent="start">
              <Button variant="outlined" onClick={() => handleChangeAllIsUse(true)}>
                옵션전체 선택
              </Button>
              <Button variant="outlined" onClick={() => handleChangeAllIsUse(false)}>
                옵션전체 해제
              </Button>
              {Brand === "SP" && (
                <Button variant="outlined" onClick={handleChangeShoesSizeCnToKr}>
                  중국신발 사이즈 한국 사이즈로 변경
                </Button>
              )}
            </RowStack>
            <Stack direction="row" gap={2} flexWrap={"wrap"}>
              {data.map((option, index) => (
                <Chip
                  label={`${option.Color} ${option.Size}`}
                  key={index}
                  color={option.IsUse ? "primary" : undefined}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    handleChangeProductByIndex(index, "IsUse", !option.IsUse);
                  }}
                />
              ))}
            </Stack>
          </Stack>

          {Object.keys(Images).length !== 0 &&
            data.map((option, index) => (
              <Stack key={index} border={"1px solid"} borderRadius={2} p={5} gap={10} sx={{ backgroundColor: option.IsUse ? "white" : "#f1f1f1" }}>
                <RowStack>
                  <Typography variant="h5">
                    NO. {index + 1} ({`${option.Color} ${option.Size}`})
                  </Typography>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="bottom"
                        control={
                          <Checkbox
                            size="medium"
                            checked={data[index].IsUse}
                            onChange={(e) => handleChangeIsUse(option.Color_zh, option.BarCode, e.target.checked)}
                          />
                        }
                        label="옵션 사용여부"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                </RowStack>
                {option.IsUse && (
                  <>
                    <Stack width={200} justifyContent={"start"} gap={2} direction="row" alignItems={"center"}>
                      <Stack gap={2}>
                        <LinkSubTitle title={`대표 썸네일`} />

                        <ImageUpload
                          width={"200px"}
                          height={"200px"}
                          index={index}
                          maxImages={1}
                          images={Images[option.BarCode]?.mainImage || []}
                          onChange={handleChangeMainImage}
                          onRemoveImage={handleRemoveMainImage}
                        />
                      </Stack>
                      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                      <Stack gap={2}>
                        <LinkSubTitle title={`추가 썸네일(3장)`} />
                        <ImageUpload
                          width={"200px"}
                          height={"200px"}
                          index={index}
                          maxImages={3}
                          images={Images[option.BarCode].additinalImage}
                          onChange={handleChangeAddImage}
                          onRemoveImage={handleRemoveAddImage}
                        />
                      </Stack>
                    </Stack>
                    <Stack flex={1} gap={5}>
                      <LinkSubTitle title={`옵션 ${index + 1}`} />

                      <Stack flex={1} flexDirection={"row"} gap={2} sx={{ mb: 3 }}>
                        <TextField
                          label="옵션"
                          variant="outlined"
                          fullWidth
                          name="Color"
                          error={!option.Color}
                          value={option.Color}
                          onChange={(e) => handleChangeColor(option.Color_zh, index, e.target.value)}
                          helperText={
                            <Stack flexDirection={"row"} gap={1}>
                              <Typography fontSize={14} color={"gray"}>
                                원본:
                              </Typography>
                              <Typography fontSize={14} color={"black"}>
                                {option.Color_zh}
                              </Typography>
                            </Stack>
                          }
                        />
                        <TextField
                          label="사이즈"
                          variant="outlined"
                          fullWidth
                          error={!option.Size}
                          name="Size"
                          value={option.Size}
                          onChange={(e) => handleChangeSize(option.Size_zh, index, e.target.value)}
                          helperText={
                            <Stack flexDirection={"row"} gap={1}>
                              <Typography fontSize={14} color={"gray"}>
                                원본:
                              </Typography>
                              <Typography fontSize={14} color={"black"}>
                                {option.Size_zh}
                              </Typography>
                            </Stack>
                          }
                        />

                        <TextField label="바코드" variant="outlined" fullWidth name="BarCode" disabled value={option.BarCode} />
                        <TextField label="SKU_ID" variant="outlined" fullWidth name="SKU_ID" disabled value={option.SKU_ID || "없음"} />
                      </Stack>

                      <LinkSubTitle title={`주문링크`} />
                      <TextField
                        label="주문링크"
                        variant="outlined"
                        sx={{ mb: 4 }}
                        size="small"
                        error={!option.OrderLink}
                        fullWidth
                        name="OrderLink"
                        value={option.OrderLink}
                        onChange={(e) => handleChangeProductByIndex(index, "OrderLink", e.target.value)}
                      />
                      <LinkSubTitle title={`가격 정보`} />
                      <Stack flex={1} alignItems={"center"} flexDirection={"row"} gap={2}>
                        <TextField
                          variant="outlined"
                          label="네이버 최저가"
                          required
                          placeholder="최저가가 없을 경우 1원으로 기재해주세요."
                          error={!option.KoreaCost}
                          size="small"
                          sx={{ width: "428px" }}
                          name="KoreaCost"
                          value={option?.KoreaCost || ""}
                          onChange={(e) => handleChangeKoreaCost(index, e.target.value ? parseInt(e.target.value) : 0)}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                          }}
                        />
                      </Stack>
                      <Stack flex={1} alignItems={"center"} flexDirection={"row"} gap={2}>
                        <TextField
                          type="number"
                          variant="outlined"
                          label="중국원가"
                          required
                          error={!option.ChinaCost}
                          size="small"
                          sx={{ width: "210px" }}
                          name="ChinaCost"
                          value={option?.ChinaCost || ""}
                          onChange={(e) => handleChangeChinaCost(index, e.target.value ? parseFloat(e.target.value) : 0)}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">위안</InputAdornment>,
                          }}
                        />
                        <TextField
                          label="현지 배송비"
                          type="number"
                          required
                          variant="outlined"
                          size="small"
                          sx={{ width: "210px" }}
                          name="LocalDeliveryFee"
                          value={option.LocalDeliveryFee || ""}
                          onChange={(e) => handleChangeLocalDeiiveryFee(index, e.target.value ? parseFloat(e.target.value) : 0)}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">위안</InputAdornment>,
                          }}
                        />
                        <TextField
                          label="쿠팡 판매가"
                          type="number"
                          error={!option.SalePrice}
                          required
                          onChange={(e) => hadleChangeCoupangSalePrice(index, e.target.value ? parseFloat(e.target.value) : 0)}
                          variant="outlined"
                          size="small"
                          sx={{ width: "210px" }}
                          name="AccountingCostRatio"
                          value={option.SalePrice || ""}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                          }}
                        />
                        <TextField
                          label="자사 판매가"
                          type="number"
                          required
                          error={!option.OurSalePrice}
                          variant="outlined"
                          size="small"
                          sx={{ width: "210px" }}
                          onChange={(e) => handleChangeOurSalePrice(index, e.target.value ? parseFloat(e.target.value) : 0)}
                          name="TotalCost"
                          value={option.OurSalePrice || ""}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                          }}
                        />
                      </Stack>
                      <Stack flex={1} flexDirection={"row"} gap={2} alignItems={"center"}>
                        <TextField
                          label="쿠팡 수수료"
                          type="number"
                          required
                          variant="outlined"
                          size="small"
                          onChange={(e) => handleChangeCoupangeCommission(index, e.target.value ? parseFloat(e.target.value) : 0)}
                          sx={{ width: "210px", backgroundColor: "#eef5fc" }}
                          name="RocketMarginRatio"
                          value={coupangeCommission[index] ? coupangeCommission[index] : COUPANGE_COMMISSION}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />{" "}
                        <TextField
                          label="자사 수수료"
                          type="number"
                          required
                          variant="outlined"
                          size="small"
                          sx={{ width: "210px", backgroundColor: "#eef5fc" }}
                          name="RocketMarginRatio"
                          value={ourCommission[index] ? ourCommission[index] : OUR_COMMISSION}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          onChange={(e) => handleChangeOurCommission(index, e.target.value ? parseFloat(e.target.value) : 0)}
                        />{" "}
                      </Stack>
                      <Stack flex={1} flexDirection={"row"} gap={2} alignItems={"center"}>
                        <TextField
                          label="총원가"
                          type="number"
                          size="small"
                          variant="outlined"
                          sx={{ width: "210px", backgroundColor: "#f1f1f1" }}
                          name="TotalCost"
                          value={option.TotalCost || 600}
                          disabled
                          InputProps={{
                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                          }}
                        />
                        <TextField
                          label="견적가(공급가)"
                          variant="outlined"
                          size="small"
                          sx={{ width: "210px", backgroundColor: "#f1f1f1" }}
                          name="TotalCost"
                          disabled
                          value={option.QuotedPrice || ""}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                          }}
                        />
                        <TextField
                          label="쿠팡 마진"
                          variant="outlined"
                          size="small"
                          sx={{ width: "210px", backgroundColor: "#f1f1f1" }}
                          name="OurMarginRatio"
                          disabled
                          error={option.RocketMargin < 0}
                          value={option.RocketMargin || ""}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                          }}
                        />
                        <TextField
                          label="자사 마진"
                          variant="outlined"
                          size="small"
                          disabled
                          sx={{ width: "210px", backgroundColor: "#f1f1f1" }}
                          name="AccountingCostRatio"
                          error={option.OurMargin < 0}
                          value={option.OurMargin || ""}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                          }}
                        />
                      </Stack>

                      <Stack flex={1} flexDirection={"row"} justifyContent={"end"}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              control={
                                <IOSSwitch value={!memoOpenList.includes(index)} onChange={(e) => handleChangeMemoOpenList(index)} sx={{ m: 1 }} />
                              }
                              label="메모"
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>
                      </Stack>
                      {memoOpenList.includes(index) && (
                        <Stack flex={1} flexDirection={"row"} gap={2}>
                          <TextField
                            id="outlined-multiline-static"
                            label="자동상품 메모"
                            multiline
                            rows={4}
                            fullWidth
                            value={option.Memo0 || ""}
                            name="Memo0"
                            onChange={(e) => handleChangeMemo(index, e)}
                          />
                          <TextField
                            id="outlined-multiline-static"
                            label="메모"
                            multiline
                            rows={4}
                            fullWidth
                            value={option.Memo00 || ""}
                            name="Memo00"
                            onChange={(e) => handleChangeMemo(index, e)}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </>
                )}
              </Stack>
            ))}
        </>
      )}
      {/* <Box flex={1}>
        <CreateNewBarCodeModal preProductID={preProductID} />
      </Box> */}
    </LinkCard>
  );
};

export default React.memo(ProductOptionsForm);

interface CreateNewBarCodeModalProps {
  preProductID: number;
}

export const CreateNewBarCodeModal: React.FC<CreateNewBarCodeModalProps> = ({ preProductID }) => {
  const [barCode, setBarCode] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const { mutateAsync: createBarCode, isPending } = useCreateBarCode();

  const [isDuplicate, setIsDuplicate] = useState<boolean>(true);

  const handleCheckDuplicate = async () => {
    customAxios.get(`/products/is_unique_barcode/?barcode=${barCode}`).then((res) => {
      setIsDuplicate(!res.data.is_unique);
      if (!res.data.is_unique) {
        customToast("error", "중복된 바코드입니다.");
      } else {
        customToast("success", "사용가능한 바코드입니다.");
      }
    });
  };

  const handleCreateBarCode = async () => {
    const dto = {
      BarCode: barCode,
      PreProductID: preProductID,
      QuotedPrice: 0,
      SalsePrice: 0,
      ChinaCost: 0,
      LocalDeliveryFee: 0,
      KoreaCost: 0,
      TotalCost: 0,
      DeliveryPrice: 0,
      VAT: 0,
      RocketMargin: 0,
      SalePrice: 0,
      AccountingCost: 0,
      OurMargin: 0,
      OurSalePrice: 0,
      TotalCount: 0,
      Status: "메인",
    };
    await createBarCode(dto);
  };

  const handleChangeOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <Button onClick={handleChangeOpen} variant="contained">
        옵션 추가{" "}
      </Button>
      <Dialog
        open={true}
        onClose={handleChangeOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "450px",
              maxWidth: "1100px",
              p: 3,
              // minWidth: "800px",
              // height: "620px", // Set your width here
            },
          },
        }}
      >
        <RowStack gap={2}>
          <TextField value={barCode} onChange={(e) => setBarCode(e.target.value)} fullWidth />
          <Button onClick={handleCheckDuplicate} disabled={isPending} variant="outlined" sx={{ width: "120px", height: "58px" }} size="large">
            중복검사
          </Button>
        </RowStack>
        <DialogActions sx={{ mt: 4 }}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={2} sx={{ width: "100%" }}>
            <Button disabled={isDuplicate} onClick={handleCreateBarCode} variant="contained" fullWidth size="large">
              {isPending ? "로딩중.." : "생성"}{" "}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
