export const STEP_LIST = [
  {
    title: "상품 등록중",
    description: "상품 등록중입니다.",
    color: "",
    backgroundColor: "",
  },
  {
    title: "검토요청",
    description: "검토요청중입니다.",
    color: "#F141E5",
    backgroundColor: "#FFEEFD",
  },
  {
    title: "최저가 검색",
    description: "최저가 검색중입니다.",
    color: "#388e3c",
    backgroundColor: "#e8f5e9",
  },
  {
    title: "확인완료",
    description: "대표님 확인건 입니다.",
    color: "#F141E5",
    backgroundColor: "#FFEEFD",
  },
  {
    title: "상세페이지 제작",
    description: "상세페이지 제작중입니다.",
    color: "#6799ff",
    backgroundColor: "#eef5fc",
  },
  {
    title: "상세페이지 검수",
    description: "견적대기중입니다.",
    color: "#F141E5",
    backgroundColor: "#FFEEFD",
  },
  {
    title: "견적대기",
    description: "견적대기중입니다.",
    color: "#f57c00",
    backgroundColor: "#fff3e0",
  },
  {
    title: "견적승인대기",
    description: "견적승인대기중입니다.",
    color: "",
    backgroundColor: "",
  },
  {
    title: "일부견적반려",
    description: "2차승인 반려건으로 온보딩으로 해결가능.",
    color: "",
    backgroundColor: "",
  },
  {
    title: "전체견적반려",
    description: "1차반려",
    color: "",
    backgroundColor: "",
  },
  {
    title: "견적완료",
    description: "발주서 발행.",
    color: "",
    backgroundColor: "",
  },
  {
    title: "정보입력",
    description: "상품의 기타 정보입력",
    color: "#388e3c",
    backgroundColor: "#e8f5e9",
  },
];
