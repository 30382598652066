import { useMutation } from "@tanstack/react-query";
import { getSearchTagDto } from "./types";
import { searchTagApi } from "./searchTagAxios";
import { customToast } from "../../shared/lib/customToast";

export const useGetSearchTag = (success: (data: string[]) => void) => {
  return useMutation<any, Error, getSearchTagDto>({
    mutationFn: (data: getSearchTagDto) => searchTagApi.getSearchTag(data),
    onSuccess: (data) => {
      success(data);
    },
    onError: (err) => {
      customToast("info", "검색 태그를 불러오는데 실패했습니다.");
    },
  });
};
