import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNewGetProductList } from "../../service/product/productHooks";
import { useEffect, useState } from "react";
import RefreshIconButton from "../../shared/ui/buttom/RefreshIconButton";
import RowStack from "../../shared/ui/RowStack";
import ShowInventoryButton from "../show-inventory-modal/ui/ShowInventoryButton";
import { useCreateTargetFind } from "../../service/target-find/targetFindHooks";
import { ItemResponse } from "../../service/order/types";
import { useUpdateOrderItem } from "../../service/order/orderHooks";
import { useUserInfoState } from "../../store/UserInfoStore";
import { getCurrentFormattedTime } from "../../shared/lib/getCurrentFormattedTime";

interface Props {
  open: boolean;
  handleClose: () => void;
  ProductInfo: ItemResponse;
  OrderAlias: string;
  onUpdate: () => void;
}

export const ShowProductListSameNameModal: React.FC<Props> = ({ open, handleClose, ProductInfo, OrderAlias, onUpdate }) => {
  const [countList, setCountList] = useState<number[]>([]);
  const { mutateAsync: createTargetFind } = useCreateTargetFind();
  const { mutateAsync: updateOrderItem } = useUpdateOrderItem();
  const { UserName } = useUserInfoState();
  const { data, refetch } = useNewGetProductList({
    brand: "",
    status: "",
    page: 1,
    page_size: 100,
    sortby: "BarCode",
    direction: "asc",
    searchText: ProductInfo.ProductName_ko.trim(),
  });

  useEffect(() => {
    if (data && data.results) {
      const temp = data.results.map(() => 0);
      setCountList(temp);
    }

    refetch();
  }, [open, data]);

  const handleUpdateCountList = (index: number, count: number) => {
    const temp = [...countList];
    temp[index] = count;
    setCountList(temp);
  };

  const handleSubmit = async (index: number, Size: string) => {
    const BarCode = data?.results[index].BarCode || "";
    const targetSize = data?.results[index].Size || "";
    const Quantity_Target = countList[index];
    const currentTime = getCurrentFormattedTime().split(" ")[0];

    let Detail01 = "";
    let Memo3 = "";

    if (Size === ProductInfo.Size) {
      Detail01 = `[${currentTime} ${UserName}]\n발주서 : ${OrderAlias}\n${Quantity_Target}장 찾기 `;
      Memo3 = `[${currentTime} ${UserName}]\n${Quantity_Target}장 찾기 `;
    } else {
      Detail01 = `[${currentTime} ${UserName}]\n발주서 : ${OrderAlias}\n${targetSize} -> ${ProductInfo.Size} ${Quantity_Target}장 택갈이 `;
      Memo3 = `[${currentTime} ${UserName}]\n${targetSize} -> ${ProductInfo.Size} ${Quantity_Target}장 택갈이 `;
    }

    await createTargetFind([{ BarCode, Quantity_Target, Detail01, Detail02: "" }]);
    await updateOrderItem({ OrderItemID: ProductInfo.OrderItemID, Memo3: ProductInfo.Memo3 ? ProductInfo.Memo3 + "\n" + Memo3 : Memo3 });
    setCountList(countList.map(() => 0));
    onUpdate();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "900px",
            height: "620px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <RowStack>
          <Typography variant="subtitle1" color="#333" fontWeight={"bold"}>
            {ProductInfo.ProductName_ko}, {ProductInfo.Size}, {ProductInfo.Color}
          </Typography>
          <RefreshIconButton
            onClick={() => {
              refetch();
            }}
          />
        </RowStack>
      </DialogTitle>
      <DialogContent sx={{ width: "850px" }}>
        <TableContainer sx={{ height: 430, boxSizing: "border-box", border: "0.5px solid #DFDFDF" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }} align={"left"} style={{ minWidth: 100 }}>
                  바코드
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 100 }}>
                  컬러
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 60 }}>
                  사이즈
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 60 }}>
                  재고
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 90 }}>
                  찾을 수량
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 140 }}>
                  임의찾기 등록
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.results &&
                data.results.map((item, i) => {
                  if (item.Color === ProductInfo.Color) {
                    // if (true) {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={i}
                        sx={item.Size === ProductInfo.Size ? { backgroundColor: "#d3e3fd", color: "blue" } : {}}
                      >
                        <TableCell align={"left"}>{item.BarCode}</TableCell>
                        <TableCell align={"center"}>{item.Color}</TableCell>
                        <TableCell align={"center"}>{item.Size}</TableCell>
                        <TableCell align={"center"}>
                          <ShowInventoryButton BarCode={item.BarCode} TotalCount={item.TotalCount || 0} />
                        </TableCell>
                        <TableCell align={"center"}>
                          <TextField
                            // disabled={item.Size === ProductInfo.Size}
                            type="number"
                            value={countList[i]}
                            sx={{ width: "80px" }}
                            onChange={(e) => handleUpdateCountList(i, parseInt(e.target.value))}
                          />
                        </TableCell>
                        <TableCell align={"center"}>
                          <Button
                            variant="contained"
                            disabled={countList[i] === 0}
                            size="large"
                            onClick={() => {
                              handleSubmit(i, item.Size);
                            }}
                          >
                            임의찾기 등록
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return null;
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleClose} variant="outlined">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};
