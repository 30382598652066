import { IconButton, Step, StepLabel, Stepper } from "@mui/material";
import LinkCard from "../LinkCard";
import LinkSubTitle from "../LinkSubTitle";
import { getPreProductDto } from "../../../../service/product/types";
import RowStack from "../../../../shared/ui/RowStack";

interface ProductProcessStepProps {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
}

const ProductProcessStep: React.FC<ProductProcessStepProps> = ({ data, onChange }) => {
  const handleChangeStep = (value: number) => {
    onChange((prev: getPreProductDto) => ({ ...prev, PreProductStatus: value }));
  };

  const handleNextStep = () => {
    onChange((prev: getPreProductDto) => ({ ...prev, PreProductStatus: Math.min(5, (data.PreProductStatus || 0) + 1) }));
  };

  const handlePrevStep = () => {
    onChange((prev: getPreProductDto) => ({ ...prev, PreProductStatus: Math.max(0, (data.PreProductStatus || 0) - 1) }));
  };

  return (
    <LinkCard>
      <LinkSubTitle title="진행상황" />
      <RowStack>
        <IconButton onClick={handlePrevStep}>
          <ArrowBackIosIcon />
        </IconButton>
        <Stepper activeStep={data.PreProductStatus} alternativeLabel sx={{ width: "100%" }}>
          {STEP_LIST.map((item, i) => (
            <Step key={i} sx={{ cursor: "pointer" }}>
              <StepLabel onClick={() => handleChangeStep(i)} sx={{ cursor: "pointer" }}>
                {item.title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <IconButton onClick={handleNextStep}>
          <ArrowForwardIosIcon />
        </IconButton>
      </RowStack>
    </LinkCard>
  );
};

export default ProductProcessStep;
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { STEP_LIST } from "../../const/stepList";
