import { Stack, Typography, FormControl, InputLabel, Select, MenuItem, Card, Tooltip } from "@mui/material";
import LinkCard from "../LinkCard";
import LinkSubTitle from "../LinkSubTitle";
import { getPreProductDto } from "../../../../service/product/types";
import { useEffect, useState } from "react";
import { productApi } from "../../../../service/product/productAxios";
import { customAxios } from "../../../../config/axios-config";
import { dopalCategory } from "../../const/dopalCategory";
import { findPath } from "../../lib/findPath";
import RowStack from "../../../../shared/ui/RowStack";
import { getSearchTagDto } from "../../../../service/search-tag/types";
import { useGetSearchTag } from "../../../../service/search-tag/searchTagHooks";

interface Props {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
}

//사용하면 안되는 카테고리5

const CategoryForm: React.FC<Props> = ({ data, onChange }) => {
  const [category, setCategory] = useState<any>({});
  const [list, setList] = useState<string[]>(["패션의류", "", "", ""]);
  const [dopalList, setDopalList] = useState<string[]>(["여성의류", ""]);
  const [detailList, setDetailList] = useState<string[]>([]);

  useEffect(() => {
    if (localStorage.getItem("category")) {
      setCategory(JSON.parse(localStorage.getItem("category") || "{}"));
    } else {
      productApi.getCoupangCategory().then((res) => {
        setCategory(res);
        localStorage.setItem("category", JSON.stringify(res));
      });
    }
  }, [data.Category_CP, data.Category_D8]);

  useEffect(() => {
    if (data.Category_CP && !isNaN(parseInt(data?.Category_CP))) {
      const path = findPath(parseInt(data.Category_CP), category);
      if (!!path && typeof path === "object") {
        setList([...path, "", "", "", ""].slice(0, 4));
      }
    }

    const path = data.Category_D8?.split("-") || [];
    if (path.length === 2) {
      setDopalList(path);
    }
  }, [category]);

  useEffect(() => {
    let target = category;
    for (let i = 0; i < list.length; i++) {
      if (list[i] === "") {
        break;
      } else {
        target = target[list[i]];
      }
    }

    if (typeof target === "number") {
      onChange((prev: getPreProductDto) => ({ ...prev, Category_CP: target.toString() }));
      customAxios.get(`/get_category_coupang_inner/?category_id=${target}`).then((res) => {
        setDetailList(res.data);
      });
    }

    if (dopalList[0] !== "" && dopalList[1] !== "") {
      onChange((prev: getPreProductDto) => ({ ...prev, Category_D8: dopalList[0] + "-" + dopalList[1] }));
    }
  }, [list, dopalList]);

  const handleChangeCoupangeCategory = (e: any, index: number) => {
    const value = e.target.value;

    const newData = [...list];
    newData[index] = value;

    for (let i = index + 1; i < newData.length; i++) {
      newData[i] = "";
    }

    setList(newData);
    handleChangeCoupangeDetailCategory("");
  };

  const handleChangeDopalCategory = (e: any, index: number) => {
    const value = e.target.value;

    const newData = [...dopalList];
    newData[index] = value;

    for (let i = index + 1; i < newData.length; i++) {
      newData[i] = "";
    }

    setDopalList(newData);
  };

  const handleChangeCoupangeDetailCategory = (value: string) => {
    onChange((prev: getPreProductDto) => ({ ...prev, Category_CP_detail: value }));
  };
  return (
    <LinkCard>
      <LinkSubTitle title="카테고리" required />
      <Stack gap={1}>
        <Stack direction={"row"} justifyContent={"start"}>
          <Stack justifyContent={"center"} px={5}>
            <Typography>쿠팡:</Typography>
          </Stack>
          <Stack>
            <Stack direction={"row"} justifyContent={"start"} gap={2}>
              <FormControl sx={{ m: 1, width: "197px" }} size="small">
                <InputLabel id="demo-select-small-label">카테고리1</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={list[0]}
                  error={!list[0]}
                  onChange={(e) => handleChangeCoupangeCategory(e, 0)}
                  label="카테고리1"
                >
                  <MenuItem value="">
                    <em>(선택안함)</em>
                  </MenuItem>
                  {category &&
                    Object.keys(category).map((key: string, index: number) => (
                      <MenuItem value={key} key={key}>
                        {key}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              {category && category[list[0]] && Object.keys(category[list[0]]).length > 0 && (
                <FormControl sx={{ m: 1, width: "197px" }} size="small">
                  <InputLabel id="demo-select-small-label">카테고리2</InputLabel>
                  <Select
                    error={!list[1]}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={list[1]}
                    onChange={(e) => handleChangeCoupangeCategory(e, 1)}
                    label="카테고리2"
                  >
                    <MenuItem value="">
                      <em>(선택안함)</em>
                    </MenuItem>
                    {Object.keys(category[list[0]]).map((key: string, index: number) => (
                      <MenuItem value={key} key={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {category && category[list[0]] && category[list[0]][list[1]] && Object.keys(category[list[0]][list[1]]).length > 0 && (
                <FormControl sx={{ m: 1, width: "197px" }} size="small">
                  <InputLabel id="demo-select-small-label">카테고리3</InputLabel>
                  <Select
                    error={!list[2]}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={list[2]}
                    onChange={(e) => handleChangeCoupangeCategory(e, 2)}
                    label="카테고리3"
                  >
                    <MenuItem value="">
                      <em>(선택안함)</em>
                    </MenuItem>
                    {Object.keys(category[list[0]][list[1]])
                      .filter((v) => {
                        return !EXCLUDED_CATEGORIES.some((disable) => v.includes(disable));
                      })
                      .map((key: string, index: number) => (
                        <MenuItem value={key} key={key}>
                          {key}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              {category &&
                category[list[0]] &&
                category[list[0]][list[1]] &&
                category[list[0]][list[1]][list[2]] &&
                Object.keys(category[list[0]][list[1]][list[2]]).length > 0 && (
                  <FormControl sx={{ m: 1, width: "197px" }} size="small">
                    <InputLabel id="demo-select-small-label">카테고리4</InputLabel>
                    <Select
                      error={!list[3]}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={list[3]}
                      onChange={(e) => handleChangeCoupangeCategory(e, 3)}
                      label="카테고리4"
                    >
                      <MenuItem value="">
                        <em>(선택안함)</em>
                      </MenuItem>
                      {Object.keys(category[list[0]][list[1]][list[2]]).map((key: string, index: number) => (
                        <MenuItem value={key} key={key}>
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
            </Stack>
            {detailList.length > 0 && (
              <FormControl sx={{ m: 1 }} size="small">
                <InputLabel id="demo-select-small-label">카테고리5</InputLabel>
                <Select
                  error={!data.Category_CP_detail}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={data.Category_CP_detail}
                  onChange={(e) => handleChangeCoupangeDetailCategory(e.target.value)}
                  label="카테고리5"
                >
                  <MenuItem value="">
                    <em>(선택안함)</em>
                  </MenuItem>
                  {detailList
                    .filter((v) => {
                      return !EXCLUDED_CATEGORIES.some((disable) => v.includes(disable));
                    })
                    .map((key: string, index: number) => (
                      <MenuItem value={key} key={key}>
                        {key}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </Stack>

        <Stack flexDirection={"row"} sx={{ mt: 5 }}>
          <Stack justifyContent={"center"} px={5}>
            <Typography>도팔:</Typography>
          </Stack>
          <Stack flex={1} flexDirection={"row"} gap={2}>
            <FormControl sx={{ m: 1, minWidth: 120, flex: 1 }} size="small">
              <InputLabel id="demo-select-small-label">카테고리1</InputLabel>
              <Select
                error={!dopalList[0]}
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={dopalList[0]}
                onChange={(e) => handleChangeDopalCategory(e, 0)}
                label="카테고리1"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.keys(dopalCategory).map((key: string, index: number) => (
                  <MenuItem value={key} key={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120, flex: 1 }} size="small">
              <InputLabel id="demo-select-small-label">카테고리2</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                error={!dopalList[1]}
                value={dopalList[1]}
                onChange={(e) => handleChangeDopalCategory(e, 1)}
                label="카테고리2"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dopalCategory[dopalList[0]] &&
                  Object.keys(dopalCategory[dopalList[0]]).map((key: string, index: number) => (
                    <MenuItem value={key} key={key}>
                      {key}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
      <RowStack justifyContent="end" sx={{ mt: 2 }}>
        <Tooltip
          title={
            <p>
              카테고리 선택이 어렵다면?
              <br /> 가이드를 확인하세요!
            </p>
          }
          arrow
          placement="top"
        >
          <a href="https://first-show-1dc.notion.site/7e81212b622f4d08b5d725f473575390?pvs=4">카테고리 가이드</a>
        </Tooltip>
      </RowStack>
    </LinkCard>
  );
};

export default CategoryForm;

const EXCLUDED_CATEGORIES = ["빅사이즈", "해외직구", "수영/수상스포츠"];
