import axios from "axios";
import { getSearchTagDto } from "./types";

const BARCODE_ALERT_URL = "https://www.atterlic.com/v1/api/searchTag";
// const BARCODE_ALERT_URL = "http://localhost:8080/api/searchTag";

export const searchTagApi = {
  getSearchTag: async (body: getSearchTagDto): Promise<any> => {
    return await axios.post(`${BARCODE_ALERT_URL}`, body).then((res) => res.data);
  },
};
