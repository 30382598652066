import { getOriginalProductResponseDto, getPreProductDto } from "../../../service/product/types";

export const FORM_LIST = ["상품정보", "카테고리", "검색태그", "옵션정보", "상세페이지", "섬유의조성", "상품세부정보"];

export const checkForm = (preProduct: getPreProductDto, products: getOriginalProductResponseDto[], title: string) => {
  switch (title) {
    case FORM_LIST[0]: //상품정보
      const { ProductName_ko, StyleNumber, purchaseadress, SupplierCompany } = preProduct;
      if (SupplierCompany === "vvic") {
        return ProductName_ko && StyleNumber && purchaseadress;
      } else {
        return ProductName_ko;
      }
    case FORM_LIST[1]: //카테고리
      const { Category_CP, Category_CP_detail, Category_D8 } = preProduct;
      return Category_CP && Category_CP_detail && Category_D8;
    case FORM_LIST[2]: //검색태그
      const { SearchTag } = preProduct;
      return SearchTag?.length > 0 && SearchTag.length <= 150 && SearchTag.split(",").length > 5;
    case FORM_LIST[3]: //옵션정보
      const isOptionsExist = products.length > 0;
      const isOptionsValueExist = products
        .filter((v) => v.IsUse)
        .every(
          // 모든 상품이 조건을 만족해야함
          (product) =>
            product.Color ||
            product.Size ||
            product.OrderLink ||
            (product.ChinaCost > 0 && product.ChinaCost < 500) ||
            !product.LocalDeliveryFee ||
            product.LocalDeliveryFee < 50 ||
            product.SalePrice > 0 ||
            product.OurSalePrice > 0 ||
            product.RocketMargin > 0 ||
            product.OurMargin > 0
        );
      const isOptionsImageExist = products.some((product) => product.ImageLink && product.ImageLink_add && product?.ImageLink_add.length === 3);
      return isOptionsExist && isOptionsValueExist && isOptionsImageExist;
    case FORM_LIST[4]: //상세페이지
      const { ImageLink_sebu } = preProduct;
      const isImageExist = ImageLink_sebu && ImageLink_sebu?.length >= 3;
      const isLabelImageExist = isImageExist && ImageLink_sebu.some((image: string) => image.includes("라벨"));
      const isDetailImageExist = isImageExist && ImageLink_sebu.some((image: string) => image.includes("상페"));
      const isInfoImageExist = isImageExist && ImageLink_sebu.some((image: string) => image.includes("인포"));
      return isImageExist && isLabelImageExist && isDetailImageExist && isInfoImageExist;
    case FORM_LIST[5]: //섬유의조성
      const { CottonInfo_en, CottonInfo_ko, CottonInfo_D8 } = preProduct;
      const percentage = CottonInfo_D8?.reduce((acc, cur) => acc + cur.value, 0);
      return percentage === 100 && CottonInfo_en && CottonInfo_ko && CottonInfo_D8;
    case FORM_LIST[6]: //상품세부정보
      const { Season, FirstSaleSeason, Laundry, BuyerName, DisplayAdress, MadeCountry } = preProduct;
      return Season && FirstSaleSeason && Laundry && BuyerName && DisplayAdress && MadeCountry;
  }
};
